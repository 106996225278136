<template>
    <div class="failedRedEnvelopes">
        <div class="content">
            <img
                src="../../assets/img/failed-red-envelopes-bg.png"
                alt=""
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'failedRedEnvelopes',
    data() {
        return {};
    },
    created() {},
    methods: {},
    mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">

.failedRedEnvelopes {
    width: 750px;
    margin: 0 auto;
    min-height: 100vh;
    background-color: #fff;
    .content {
        width: 100%;
        height: 100%;
        position: relative;
        img {
            width: 100%;
        }
    }
}
</style>
