/* 设置微信导航标题 */
let setPageTitle = function(title) {
    document.title = title;
    let ua = navigator.userAgent;
    if (/\bMicroMessenger\/([\d.]+)/.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
        var i = document.createElement('iframe');
        // i.src = '//m.baidu.com/favicon.ico'
        i.style.display = 'none';
        i.onload = function() {
            setTimeout(function() {
                i.remove();
            }, 9);
        };
        document.body.appendChild(i);
    }
};

/* 将对象转化为串式字符 */
let objToQs = (obj) =>
    Object.keys(obj).reduce((a, k) => (a += `${k}=${obj[k]}&`), '');

/* url参数转为对象 */
let qsToObj = (obj = {}) => (
    decodeURIComponent(window.location.href).replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        (m, key, value) => (obj[key] = value)
    ),
    obj
);

/* 判断是否是微信浏览器的函数 */
let isWeiXin = () =>
    window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
    'micromessenger'
        ? true
        : false;

/* 判断是否苹果机子 */
let isApple = () =>
    /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) ||
    !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

//积分金额计算
let integralToMoney = (integral) => integral / 100;

//处理车牌显示 第二个加空格
let templateShowStyle = (value) => {
    if (!value) {
        return '';
    }
    if (value.indexOf('无') > -1) {
        return value.slice(0, 1) + ' ' + value.slice(1);
    }
    return value.slice(0, 2) + ' ' + value.slice(2);
};

//发票状态
let filtersTxt = function(params) {
    //根据状态判断按钮文案   0 待开票 1 开票成功 2 开票失败 3 红冲 4 开票中（审核通过） 5 待审核  6 拒绝开票 7 作废发票
    switch (params) {
        case 0:
            return '申请开票';
            break;
        case -1:
            return '申请开票';
            break;
        case 4:
            return '开票中';
            break;
        case 5:
            return '开票中';
            break;
        case 2:
            return '开票失败';
            break;
        case 3:
            return '开票失败';
            break;
        case 6:
            return '开票失败';
            break;
        case 7:
            return '开票失败';
            break;
        case 1:
            return '已开票，请查看邮件';
            break;
        case -2:
            return '未开启';
            break;
        default:
            break;
    }
};
//获取当前时间并格式化 yyyy-mm-dd hh:mm:ss
let getCurrentTime = () => {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    let date = now.getDate();
    let hour = now.getHours();
    let minu = now.getMinutes();
    let sec = now.getSeconds();
    month < 10 && (month = '0' + month);
    date < 10 && (date = '0' + date);
    hour < 10 && (hour = '0' + hour);
    minu < 10 && (minu = '0' + minu);
    sec < 10 && (sec = '0' + sec);
    return (
        year + '-' + month + '-' + date + ' ' + hour + ':' + minu + ':' + sec
    );
};

//hh:mm:ss
let formatTime = (seconds) => {
    var theTime = parseInt(seconds);
    var hour = '00';
    var minute = '00';
    var sec = '00';
    if (theTime > 60) {
        minute = parseInt(theTime / 60);
        sec = parseInt(theTime % 60);
    }
    if (minute > 60) {
        hour = parseInt(minute / 60);
        minute = parseInt(theTime % 60);
    }
    if (hour < 10) {
        hour = '0' + hour;
    }
    if (minute < 10) {
        minute = '0' + minute;
    }
    if (sec < 10) {
        sec = '0' + sec;
    }
    return hour + ':' + minute + ':' + sec;
};
//天：小时：分钟
let formatTimeNew = (seconds) => {
    function belowTen(n) {
        return n < 0 ? '0' + n : n;
    }
    if (seconds == '' || seconds == undefined) {
        return '';
    }
    var days = parseInt(seconds / (60 * 60 * 24));
    var hours = parseInt((seconds % (60 * 60 * 24)) / (60 * 60));
    var minutes = parseInt((seconds % (60 * 60)) / 60);
    var seconds = (seconds % 60) / 1000;
    if (days > 0) {
        return (
            days + '天' + belowTen(hours) + '小时' + belowTen(minutes) + '分钟'
        );
    } else {
        return belowTen(hours) + '小时' + belowTen(minutes) + '分钟';
    }
};

/**匹配民用车牌和使馆车牌
 ** 判断标准
 ** 1，第一位为汉字省份缩写
 ** 2，第二位为大写字母城市编码
 ** 3，后面是5位仅含字母和数字的组合
 **/
let isCarLicense = (license) => {
    return (
        //无牌车（无+11位入场码）
        /^无{1}(.*?){11}$/.test(license) ||
        /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新使]{1}[A-Z]{1}[0-9a-zA-Z]{5}$/.test(
            license
        ) ||
        //匹配特种车牌(挂,警,学,领,港,澳)
        /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新]{1}[A-Z]{1}[0-9a-zA-Z]{4}[挂警学领港澳]{1}$/.test(
            license
        ) ||
        //匹配武警车牌
        /^WJ[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新]?[0-9a-zA-Z]{5}$/i.test(
            license
        ) ||
        //#匹配军牌
        /[A-Z]{2}[0-9]{5}$/.test(license) ||
        // #匹配新能源车辆6位车牌
        // #小型新能源车
        /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新]{1}[A-Z]{1}[DABCEFGHJK]{1}[0-9a-zA-Z]{5}$/.test(
            license
        ) ||
        // #大型新能源车
        /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新]{1}[A-Z]{1}[0-9a-zA-Z]{5}[DABCEFGHJK]{1}$/.test(
            license
        )
    );
};

let wechatLogin1 = function($http, href) {
    let url = href;
    $http(`/Api/WechatLogin`, {
        method: 'get',
        params: {
            redirectUrl: url,
        },
    }).then((res) => {
        if (res.code == 200) {
            if (res.data.url) {
                console.log(res.data.url,'res.data.url');
                window.location.replace(
                    res.data.url + '?f_time=' + new Date().getTime()
                );
            }
        }
    });
};

export {
    setPageTitle,
    isWeiXin,
    isApple,
    integralToMoney,
    objToQs,
    templateShowStyle,
    getCurrentTime,
    formatTime,
    formatTimeNew,
    filtersTxt,
    qsToObj,
    isCarLicense,
    wechatLogin1,
};
