const keyboardMutations = {
    /* 键盘显示和隐藏 */
    changeKeyboardShow(store) {
        store.keyboardShow = !store.keyboardShow;
    },
    openKeyboard(store) {
        store.keyboardShow = true;
    },
    closeKeyboard(store) {
        store.keyboardShow = false;
    },
};

const plateMutations = {
    /* 车牌输入索引设置 */
    setLetterIndex(store, ids) {
        store.theLetterIndex = ids;
    },
    setPlate(store, letter) {
        store.thePlate[store.theLetterIndex] = letter;
        store.thePlate = [...store.thePlate];
        //输入的时候自动切换位置
        if (letter) {
            store.theLetterIndex += 1;
            if (store.theLetterIndex >= store.thePlate.length - 1) {
                store.theLetterIndex = store.thePlate.length - 1;
            }
        } else {
            store.theLetterIndex -= 1;
            if (store.theLetterIndex <= 0) {
                store.theLetterIndex = 0;
            }
        }
    },
    /* 新能源追加一位 */
    plateAddOneIndex(store) {
        store.thePlate.push(null);
    },

    /* 历史车牌赋值到输入框 */
    getHistoryToPlateInput(store, payload) {
        store.thePlate = payload;
    },
};

const couponMutations = {
    /* 优惠券选择框显示隐藏 */
    openCouponBox(store) {
        store.couponBoxShow = true;
    },
    closeCouponBox(store) {
        store.couponBoxShow = false;
    },
    setCouponsArray(store, coupons) {
        let tempArr = [];
        let checkedId = [];
        coupons.forEach((item) => {
            tempArr.push({
                id: item.mc_coupon_code,
                content: item.c_name,
                count: item.c_amount,
            });
            checkedId.push(item.mc_coupon_code);
        });

        if (checkedId[0]) {
            store.couponCheckedId[0] = checkedId[0];
        }

        store.couponsArray = tempArr;
        store.couponText = `已选${store.couponCheckedId.length}张优惠券`;
        store.discountNum = tempArr.reduce((acc, item) => {
            return (acc += parseFloat(item.count));
        }, 0);
    },
    setCouponText(store) {
        store.couponText = `已选${store.couponCheckedId.length}张优惠券`;
    },
    setCouponCheckedId(store, couponID) {
        let oldArr = store.couponCheckedId;
        if (oldArr.indexOf(couponID) > -1) {
            oldArr.splice(oldArr.indexOf(couponID), 1);
        } else {
            oldArr.push(couponID);
        }

        store.couponCheckedId = oldArr;
    },
    setDiscountNum(store) {
        let num = 0;
        store.couponCheckedId.forEach((id) => {
            num += parseFloat(
                store.couponsArray.filter((item) => item.id === id)[0].count
            );
        });
        store.discountNum = num;
    },
};

const integerMutations = {
    setIntegral(store, stepNum) {
        /* 1000实际要存到本地，交易完成需要更新 */
        store.integral = store.integral1 - stepNum;
    },
    setIntegralToMoneyInStore(store, money) {
        store.integralToMoneyInStore = parseFloat(money);
    },
    saveInteger(store, score) {
        store.integral = score;
        store.integral1 = score;
    },
};

const serversMutations = {
    /* 设置获取到的tempCarInfo */
    setTempCarInfo(store, payload) {
        if (!payload.carNo) {
            return;
        }
        store.tempCarInfo = payload;
    },
};


export {
    keyboardMutations,
    plateMutations,
    couponMutations,
    integerMutations,
    serversMutations
};
