import Vue from 'vue';
import Vuex from 'vuex';
import {
    keyboardMutations,
    plateMutations,
    couponMutations,
    integerMutations,
    serversMutations,
} from './mutation';
import {couponActions} from './action';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        /* 键盘 */
        keyboardShow: false,

        /* 车牌输入 */
        isNewEnergy: true,
        thePlate: ['粤', 'B', null, null, null, null, null],
        theLetterIndex: 0,

        /* 优惠券 */
        couponBoxShow: false,
        couponsArray: [],
        couponText: '选择优惠券',
        couponCheckedId: [],

        /* 积分 */
        integral: 0,
        integral1: 0,
        integralToMoneyInStore: 0,
        discountNum: 0,

        /* 查询到的临时停车信息 */
        tempCarInfo: {},
    },
    mutations: {
        ...keyboardMutations,
        ...plateMutations,
        ...couponMutations,
        ...integerMutations,
        ...serversMutations,
        resetData(store) {
            store.couponsArray = [];
            store.couponText = '选择优惠券';
            store.couponCheckedId = [];
            store.tempCarInfo = {};
            store.integral = 0;
            store.integral1 = 0;
            store.integralToMoneyInStore = 0;
            store.discountNum = 0;
        },
    },
    actions: {
        ...couponActions
    },
    getters: {},
    modules: {},
});
