<template>
  <div class="detailb">
    <!-- 无需支付 -->
    <div class="nopay" v-if="tempCarInfo.totalFee == 0">
      <div class="detailP_wrapper">
        <div class="plate_num_show com_box_style dot">
          <span class="plates_num">{{
            templateShowStyle(tempCarInfo.carNo)
          }}</span>
        </div>
        <div class="count_box no-mt">
          <div v-if="tempCarInfo.serviceTime">
            <p class="time_box">{{ formatTimeNew(tempCarInfo.serviceTime) }}</p>
            <p class="box_title">停车时长</p>
          </div>
          <div>
            <p class="money_box">
              <span class="money_symbol">￥</span>{{ countPay }}
            </p>
            <p class="box_title">应付金额</p>
          </div>
        </div>
        <div class="com_box_style">
          商户名称 <span class="parking_lot">{{ tempCarInfo.areaName }}</span>
        </div>
        <div class="com_box_style" v-if="tempCarInfo.startTime">
          入场时间 <span class="parking_time">{{ tempCarInfo.startTime }}</span>
        </div>
        <div class="com_box_style mr-b">
          订单编号 <span class="current_time">{{ tempCarInfo.orderSn }}</span>
        </div>
      </div>

      <div class="nopay_pic_box"></div>
      <p class="nopay_txt">无需缴费 一路顺风</p>
      <!-- ad  -->
      <div v-if="false" style="margin-top: 30px">
        <a href="http://xinghe2.fl.auwinner.com">
          <img src="../../assets/img/ic_aa_main.png" alt="xinghe" style="width: 100%" />
        </a>
      </div>
      <!-- ad end -->
    </div>
    <!-- 无需支付 -->

    <!-- 信息详情 -->
    <div v-if="isShowDetialsArea()">
      <div class="detailP_wrapper">
        <div class="plate_num_show com_box_style dot">
          <span class="plates_num">{{
            templateShowStyle(tempCarInfo.carNo)
          }}</span>
          <p class="couponsUrl" v-if="couponsUrl" @click="toGetCoupons">
            兑换优惠券 >
          </p>
        </div>
        <!-- 时长，费用 商业-->
        <div class="count_box no-mt" v-if="isBs">
          <div style="word-break: break-word">
            <p class="time_box">{{ formatTimeNew(tempCarInfo.serviceTime) }}</p>
            <p class="box_title">停车时长</p>
          </div>
          <div>
            <p class="money_box">
              <span class="money_symbol">￥</span>{{ countPay }}
            </p>
            <p class="box_title">应付金额</p>
          </div>
        </div>
        <div class="count_box no-mt" v-if="!isBs">
          <div>
            <p class="time_box">{{ formatTimeNew(tempCarInfo.serviceTime) }}</p>
            <p class="box_title">停车时长</p>
          </div>
          <div>
            <p class="money_box">
              <span class="money_symbol">￥</span>{{ countPay }}
            </p>
            <p class="box_title">停车费用</p>
          </div>
        </div>
        <div>
          <div class="com_box_style" v-if="!isBs">
            所在车场 <span class="parking_lot">{{ tempCarInfo.areaName }}</span>
          </div>

          <div class="com_box_style" v-if="isBs">
            入场时间
            <span class="parking_time">{{ tempCarInfo.startTime }}</span>
          </div>
          <div class="com_box_style" v-if="!isBs">
            停车开始时间
            <span class="parking_time">{{ tempCarInfo.startTime }}</span>
          </div>
          <div class="com_box_style" v-if="!isBs">
            当前时间 <span class="current_time">{{ tempCarInfo.nowTime }}</span>
          </div>

          <!-- 优惠券 -->
          <div class="com_box_style" v-if="tempCarInfo.couponSet == 1">
            优惠券

            <p>
              <span class="link_coupon" :style="{
                  color:
                    $store.state.couponsArray.length === 0 ? '#999' : '#2ec5b6',
                }" @click="openCouponBox">
                {{
                  $store.state.couponsArray.length == 0
                    ? "暂无优惠券 "
                    : $store.state.couponText
                }}
                <img class="left_arrrow" src="../../assets/img/lit_arrow_left.png" alt="" />
              </span>
            </p>
          </div>

          <!-- 积分 -->
          <div class="com_box_style" v-if="isBs && tempCarInfo.couponSet == 1">
            可用积分
            <span class="integral">{{ $store.state.integral }}</span>
            <span class="link_coupon">
              <van-stepper v-model="integralValue" theme="round" integer :step="stepInteger" min="0" :disable-input="noInput" :disable-plus="plusAble" :max="$store.state.integral1" default-value="0" button-size="22" input-width="40px" @change="stepperValChange" />
            </span>
          </div>
          <div class="com_box_style" v-if="isBs && tempCarInfo.couponSet == 1">
            积分抵扣金额
            <span class="deduction_amount">{{ discountMoney }}</span>
          </div>

          <div class="pay-area">
            <div class="com_box_style pay-title">
              <span class="pay-type-text">支付方式</span><span v-if="isShowOpenEn" class="open-nNosense-pay" @click="goPage">开通无感支付</span>
            </div>
            <div class="components-area">
              <pay-type :defaultVal.sync="selectVal" :payTypeList="payTypeList"></pay-type>
            </div>
          </div>
        </div>
      </div>

      <!-- 缴费确认 -->
      <div class="fixed-btn" v-if="isShowDetialsArea()">
        <van-button type="default" class="btn1 btn_wrapper" @click="payMoney">立即付款（{{ timeOut }}）</van-button>
      </div>

      <!-- 优惠券选择组件 -->
      <coupon v-if="$store.state.couponBoxShow" @getCharge="getTotalPayWidthCoupon"></coupon>
    </div>
    <!-- 信息详情 -->

    <!-- 逃费 -->
    <div v-if="tempCarInfo.totalFee > 0 && payPlatform == 7">
      <div class="detailP_wrapper">
        <div class="plate_num_show com_box_style dot">
          <span class="plates_num">{{
            templateShowStyle(tempCarInfo.carNo)
          }}</span>
        </div>
        <div class="com_box_style">
          商户名称
          <span class="parking_lot">{{ tempCarInfo.areaName }}</span>
        </div>
        <div class="com_box_style">
          订单编号 <span class="current_time">{{ tempCarInfo.orderSn }}</span>
        </div>
      </div>
      <!-- 时长，费用 产业-->
      <div class="count_box">
        <div>
          <p class="money_box">
            <span class="money_symbol">￥</span>{{ tempCarInfo.totalFee }}
          </p>
          <p class="box_title">停车费用</p>
        </div>
      </div>

      <!-- 缴费确认 -->
      <div class="fixed-btn">
        <van-button type="default" class="btn1 btn_wrapper" @click="payMoney">立即付款（{{ timeOut }}）</van-button>
      </div>
    </div>
    <!-- 逃费 -->

    <!-- ad  -->
    <div v-if="false" style="margin-top: 30px">
      <a href="http://xinghe2.fl.auwinner.com">
        <img src="../../assets/img/ic_aa_main.png" alt="xinghe" style="width: 100%" />
      </a>
    </div>
    <div v-if="false" style="height: 80px"></div>
    <!-- ad end -->
  </div>
</template>

<script>
import area from "../../config/area";
import { accMul } from "../../config/math.js";
import NP from "number-precision";
import { coupon } from "../../components";
import payType from "../../components/payTypeComponents/payType";

import invoiceConfig from "../../config/invoiceConfig";
import { mapState } from "vuex";
import { Toast } from "vant";
import {
  isWeiXin,
  formatTime,
  formatTimeNew,
  templateShowStyle,
} from "../../config/utils.js";

export default {
  data: () => ({
    selectVal: "1", // 支付方式
    payTypeList: [
      // 支付方式列表渲染数据
      {
        text: "微信支付",
        val: "1",
        url: require("../../assets/img/wechat-pay.png"),
        slogan: "", // 所属当前支付类型的宣传语
      },
      // {
      //     text: '钱包支付 ',
      //     val: '2',
      //     slogan: '随机312321321321优惠', // 所属当前支付类型的宣传语
      //     url: require("../../assets/img/wallet-pay.png")
      // }
    ],
    isBs: true, //是否是商业,即积分展示项
    vip_code: "",
    integralValue: 0, //选择的积分数量
    stepInteger: 1, //积分增减步长
    discountMoney: 0,
    exchangeRate: "", //每个步长对应的金额
    exchangeMoney: 0, //实际兑换的金额
    timer: "",
    timeOut: 120,
    areaShow: "",
    countPay: 0, //停车费用
    plusAble: false, //积分增加控制
    syncPay: "", //同步金额
    couponsUrl: "", //领取优惠券链接
    noInput: true,
    payPlatform: 2, //2微信，7无人值守支付方式
    redirect: "", //crm 域名 结果页面跳转
    costIntergal: "",
    elbonus: 0, // 规定金额使用数值，用于限制积分加减
    isShowOpenEn: false, // 是否开通无感支付
  }),
  created() {
    // 放在session  关闭可以清除,小程序支付需要

    if (this.$route.query.source) {
      sessionStorage.setItem("source", this.$route.query.source);
      sessionStorage.setItem("payOpenId", this.$route.query.payOpenId);
    }

    //ios不刷新问题
    window.addEventListener("pageshow", function (e) {
      // 通过persisted属性判断是否存在 BF Cache
      if (e.persisted) {
        window.location.reload();
      }
    });

    this.openId =
      this.$route.query.openId || sessionStorage.getItem("openId") || "";
    //没有获取到openId，使用微信静默授权登录

    !this.openId && this.$http.wechatLogin(window.location.href);

    sessionStorage.setItem("openId", this.openId);
    this.payPlatform = this.$route.query.payPlatform || 2;

    //crm域名保存
    this.redirect = this.$route.query.redirect || "";
    if (this.redirect) {
      localStorage.setItem("CRMurl", this.redirect);
    } else {
      localStorage.removeItem("CRMurl", this.redirect);
    }
    if (!this.openId) {
      return;
    }
    if (this.payPlatform == 7) {
      this.getDataFromUrl();
      this.countTime();
    } else {
      this.initShowInfo("created");
      /* 获取页面详情类型，商业,产业,物业 */
      this.detailType();
      this.countTime();
    }
    //查询优惠劵
    // this.chargeCoupons()
  },
  updated() {
    //积分禁用判断
    if (
      this.$store.state.integral < this.stepInteger ||
      this.countPay <= 0 ||
      this.getShowMoney <= 0 ||
      (this.elbonus && this.integralValue >= this.elbonus)
    ) {
      this.plusAble = true;
    } else {
      this.plusAble = false;
    }
  },

  computed: {
    // 临停信息
    ...mapState(["tempCarInfo"]),
    getShowMoney() {
      // let allMoney = accSub(
      //     this.countPay,
      //     this.$store.state.integralToMoneyInStore
      // )

      // let allMoney = NP.minus(Number(this.countPay), this.$store.state.integralToMoneyInStore)
      // /* 金额小于0时禁用计步器累加 */
      // if (allMoney <= 0) {
      //     allMoney = 0
      //     this.plusAble = true
      // } else {
      //     this.plusAble = false
      // }
      // return allMoney

      if (Number(this.countPay) <= 0) {
        this.plusAble = true;
      } else {
        this.plusAble = false;
      }

      return this.countPay;
    },
  },

  methods: {
    formatTime,
    formatTimeNew,
    templateShowStyle,
    /**
     * 检测无感支付状态
     */
    checkOpenStatus() {
      let _rq = this.$route.query;
      let oParams = {
        carNo: _rq.plate || this.tempCarInfo.carNo,
        openId: _rq.openId,
        area: _rq.area || this.tempCarInfo.curArea,
      };
      this.$http.postCheckAutoPay(oParams).then((res) => {
        // -521==当前车牌开通无感支付
        if (res.code == -521) {
          this.isShowOpenEn = true;
        } else {
          this.isShowOpenEn = false;
        }
      });
    },
    goPage() {
      this.$router.push({
        path: "/senselessPayment",
        query: {
          carNo: this.tempCarInfo.carNo,
          openId: this.$route.query.openId,
          area: this.$route.query.area || this.tempCarInfo.curArea,
        },
      });
    },
    isShowDetialsArea() {
      // 支付金额>0并且类型不为无人值守支付方式时
      return this.tempCarInfo.totalFee > 0 && this.payPlatform != 7;
    },
    toGetCoupons() {
      location.href = this.couponsUrl;
    },
    /* 优惠券显示 */
    openCouponBox() {
      if (this.$store.state.couponsArray.length === 0) {
        return;
      }
      /*  this.plusAble = true; */
      this.$store.commit("openCouponBox");
    },
    /* 积分增减 */
    stepperValChange(val) {
      // 停车场积分兑换金额,积分数值大于等于限制会会员消费积分数值时
      if (this.elbonus && this.stepInteger >= this.elbonus) {
        const toast1 = Toast({
          message: `每次可兑换停车费积分：${this.elbonus} 积分`,
          duration: 2000,
        });
        this.$nextTick(() => {
          this.integralValue = 0;
        });
        return false;
      } else if (this.elbonus && val >= this.elbonus) {
        // 当前使用积分数值大于等于限制会会员消费积分数值，将数值还原至小于等于会员消费积分数值
        const toast1 = Toast({
          message: `每次可兑换停车费积分：${this.elbonus} 积分`,
          duration: 2000,
        });
        this.$nextTick(() => {
          this.integralValue = this.integralValue - this.stepInteger;
        });
        return false;
      }

      if (this.integralValue <= 0) this.integralValue = 0;
      if (this.integralValue >= this.$store.state.integral1)
        this.integralValue = this.$store.state.integral1;

      //积分更新到store
      this.$store.commit("setIntegral", this.integralValue);
      let countShowMoney = this.getIntegerToMoney(this.integralValue);
      this.discountMoney = `￥${countShowMoney}`;
      //积分消耗更新到store
      this.$store.commit("setIntegralToMoneyInStore", countShowMoney);
      this.getTotalPayWidthCoupon();
    },
    /* 积分兑换金额计算 */
    getIntegerToMoney(val) {
      let rate = val / this.stepInteger;
      let integarMoney = accMul(rate, this.exchangeRate);
      this.exchangeMoney = integarMoney;
      return integarMoney;
    },
    /* url获取数据，逃费 */
    getDataFromUrl() {
      let obj = {
        carNo: this.$route.query.plate,
        totalFee: this.$route.query.totalFee,
        orderSn: this.$route.query.orderSn,
        areaName: this.$route.query.areaName || "智善生活",
        curArea: this.$route.query.area,
        sys: this.$route.query.sys || "",
      };
      this.syncPay = obj.totalFee;
      this.$store.commit("setTempCarInfo", obj);
    },
    /* 参数获取 */
    detailType() {
      this.isBs = this.tempCarInfo.group == 3 ? true : false;

      this.vip_code = this.$route.query.vip_code || "";
    },
    /* 初始化展示信息 */
    async initShowInfo(type) {
      if (!this.tempCarInfo.carNo) {
        await this.searchCarInfo();
        this.detailType();
        this.countPay = this.tempCarInfo.totalFee;
      }
      //车场和商家
      this.countPay = this.tempCarInfo.totalFee;
      // 第一次进入页面是时如果金额为0则不请求优惠券接口
      if (type == "created") {
        if (Number(this.tempCarInfo.totalFee) != 0) {
          this.chargeCoupons();
        }
      } else {
        this.chargeCoupons();
      }
      // 1 代表当前项目开通无感支付,否则就是未开通
      if (this.tempCarInfo.wechat == 1) {
        this.checkOpenStatus();
      }
    },
    /* 倒计时120s */
    countTime() {
      //若为宁乡停车场,则倒计时改为300秒
      if (this.$route.query.area === "NXCCT") {
        this.timeOut = 300;
        this.timer = setInterval(async () => {
          this.timeOut = this.timeOut - 1;
          if (this.timeOut <= 0) {
            this.timeOut = 300;
            if (this.payPlatform != 7) {
              this.discountMoney = 0;
              this.$store.commit("resetData");
              await this.searchCarInfo();
              this.initShowInfo("created");
            }
          }
        }, 1000);
      } else {
        this.timer = setInterval(async () => {
          this.timeOut = this.timeOut - 1;
          if (this.timeOut <= 0) {
            this.timeOut = 120;
            if (this.payPlatform != 7) {
              this.discountMoney = 0;
              this.$store.commit("resetData");
              await this.searchCarInfo();
              this.initShowInfo("created");
            }
          }
        }, 1000);
      }
    },
    /* 查询信息 */
    async searchCarInfo() {
      await this.$store.dispatch("searchCarInfo", {
        carNo: this.$route.query.plate || this.$route.query.No,
        area: this.$route.query.area,
        openId: this.$route.query.openId || this.openId,
        equipCode: this.$route.query.equipCode || "",
        key: this.$route.query.key || "",
      });
    },
    //获取优惠券
    async getCoupos() {
      let { data } = await this.$http.postCoupons({
        carNo: this.tempCarInfo.carNo,
        orderSn: this.tempCarInfo.orderSn,
        area: this.tempCarInfo.curArea,
        vipCode: this.vip_code,
        sys: this.tempCarInfo.curSys,
      });
      //数组
      if (!data || data.length <= 0) {
        return;
      }
      //优惠券存到vuex
      this.$store.commit("setCouponsArray", data.coupons || []);
      this.$store.commit("saveInteger", data.score);
      this.stepInteger = data.rule.bonus;
      this.exchangeRate = data.rule.exchange;
      this.couponsUrl = data.ex_url;

      this.elbonus = (data.rule.elbonus && Number(data.rule.elbonus)) || 0;
    },
    //优惠券开启时，获取默认优惠券使用后价格
    async chargeCoupons() {
      if (this.tempCarInfo.couponSet == 1 || this.tempCarInfo.group == 3) {
        await this.getCoupos();

        if (this.$store.state.couponCheckedId.length >= 1) {
          await this.getTotalPayWidthCoupon();
        }
      }
    },
    //获取使用优惠后的价格
    async getTotalPayWidthCoupon() {
      try {
        let { data } = await this.$http.postRechargePay({
          carNo: this.tempCarInfo.carNo,
          orderSn: this.tempCarInfo.orderSn,
          couponsCode: this.$store.state.couponCheckedId.join(","),
          area: this.tempCarInfo.curArea,
          sys: this.tempCarInfo.curSys,
          score: this.integralValue,
        });

        if (!data) {
          this.countPay = this.tempCarInfo.totalFee;
          this.syncPay = this.tempCarInfo.totalFee;
        } else {
          this.countPay = data.totalFee;
          this.syncPay = data.totalFee;
          /*  this.integralValue = 0; */
        }
      } catch {
        this.countPay = this.tempCarInfo.totalFee;
      }
    },
    //检查订单状态
    async checkOrderStatus() {
      try {
        let { data } = await this.$http.postCheckOrderStatus({
          orderSn: this.tempCarInfo.orderSn,
        });
        return data != 0 ? true : false;
      } catch {}
    },

    //临停上报
    async saveTempCarInfoToService() {
      this.costIntergal = this.integralValue;
      var that = this;
      try {
        if (that.payPlatform != 7) {
          let res = await this.$http.postSaveCarInfo({
            couponsCode: that.$store.state.couponCheckedId.join(","),
            carNo: that.tempCarInfo.carNo,
            orderSn: that.tempCarInfo.orderSn,
            area: that.tempCarInfo.curArea,
            sys: that.tempCarInfo.curSys,
            totalFee: that.syncPay,
            payPlatform: that.payPlatform, //微信
            score: that.integralValue,
          });
          if (res.code == 200) {
            //保存上报后的数据，给结果页面应用
            that.saveDataTolocal();
            // 上报成功进行支

            if (Number(that.syncPay) == 0) {
              // 0元直接跳无需支付页面

              //小猫支付项目
              let { data } = await this.$http.getCatList();

              if (
                data.catPark &&
                data.catPark.indexOf(that.tempCarInfo.curArea) > -1
              ) {
                that.callThePay();
                return;
              }
              that.goPayResult("ok");
            } else {
              that.callThePay();
            }
          } else if (res.code == -516) {
            that.$router.push({
              path: "/resultsPage",
              query: {
                v: +new Date(),
                code: res.code,
              },
            });
          }
        } else {
          let res = await this.$http.postSaveCarInfo({
            carNo: that.tempCarInfo.carNo,
            orderSn: that.tempCarInfo.orderSn,
            area: that.tempCarInfo.curArea || that.$route.query.area,
            sys: that.tempCarInfo.sys,
            totalFee: that.tempCarInfo.totalFee,
            payPlatform: that.payPlatform, //微信
          });
          if (res.code == 200) {
            that.saveDataTolocal();
            // 上报成功进行支付
            if (Number(that.syncPay) == 0) {
              // 0元直接跳无需支付页面

              that.goPayResult("ok");
            } else {
              that.callThePay();
            }
          }
        }
      } catch {}
    },
    //调起支付
    async callThePay() {
      var that = this;
      //调接口

      // 判断是否小程序环境
      var source = sessionStorage.getItem("source");
      var payOpenId = sessionStorage.getItem("payOpenId");
      var url = window.location.href.split("#/")[0] + "#/mpPay";

      url = encodeURIComponent(url);
      if (source && source == "cocogo") {
        //2023.7.5
        //payOpenId为 支付走小程序分支 时所需要的参数，实际为换取openId的code，由需要打开H5支付的小程序拼接提供
        location.href = `./Api/WxPayParking?orderSn=${
          this.tempCarInfo.orderSn
        }&payAmount=${
          this.syncPay
          }&t=${new Date().getTime()}&source=cocogo&backurl=${url}&payOpenId=${payOpenId}`;
      } else {
        /*
          先判断车场，对应车场调起银联支付接口，获取银联支付链接
        */
        let area = that.tempCarInfo.curArea || that.$route.query.area;
        let IsUnionAreaRes = await this.$http.IsUnionArea({
          area: area,
        });
        if (IsUnionAreaRes.data.state === 1) {
          let res = await this.$http.GetUnionPay({
            orderSn: that.tempCarInfo.orderSn,
            payAmount: that.tempCarInfo.totalFee,
            t: new Date().getTime(),
          });
          //设置银联支付标识符，用于结果页判断是否走银联支付的流程
          localStorage.setItem("unionPay", true);
          location.replace(res.data.data.pay_url);
        } else {
          location.href = `./Api/WxPayParking?orderSn=${
            this.tempCarInfo.orderSn
          }&payAmount=${this.syncPay}&t=${new Date().getTime()}`;
        }
      }

      return;
    },
    //获取选中的优惠总和
    getPriceCouponsChecked() {
      let pirce = [];
      let times = [];
      this.$store.state.couponCheckedId.forEach((item) => {
        this.$store.state.couponsArray.forEach((coupon) => {
          if (item == coupon.id && coupon.content.indexOf("元") > -1) {
            pirce.push(coupon.count);
          }
          if (item == coupon.id && coupon.content.indexOf("小时") > -1) {
            times.push(coupon.count);
          }
        });
      });

      let freePrice = pirce.reduce((acc, item) => {
        return (acc += Number(item));
      }, 0);

      let freeTiem = times.reduce((acc, item) => {
        return (acc += Number(item));
      }, 0);

      freeTiem *= 3600;
      return { freePrice, freeTiem };
    },

    //进入支付结果页面 nopay success fail
    goPayResult(showLogin) {
      let that = this;
      that.$router.push({
        path: "/parkPayResult",
        query: {
          result: showLogin,
          plate: that.tempCarInfo.carNo,
          money: that.syncPay,
          startTime: that.tempCarInfo.startTime,
          orderSn: that.tempCarInfo.orderSn,
          parkTime: that.tempCarInfo.serviceTime,
          area: that.tempCarInfo.curArea,
          areaName: that.tempCarInfo.areaName,
          payWay: "其他",
          openId: that.$route.query.openId,
          vip_code: that.vip_code,
          equipCode: that.$route.query.equipCode || "",
          key: that.$route.query.key || "",
          freeAmount: that.getPriceCouponsChecked().freePrice || 0,
          freeTime: that.getPriceCouponsChecked().freeTiem || 0,
          score: that.costIntergal || 0,
        },
      });
    },

    /* 保存信息到本地，用于结果页面 */
    saveDataTolocal() {
      localStorage.setItem(
        "resultData",
        JSON.stringify({
          plate: this.tempCarInfo.carNo,
          money: this.syncPay,
          startTime: this.tempCarInfo.startTime,
          orderSn: this.tempCarInfo.orderSn,
          parkTime: this.tempCarInfo.serviceTime,
          area: this.tempCarInfo.curArea,
          areaName: this.tempCarInfo.areaName,
          payType: "微信支付",
          openId: this.$route.query.openId,
          vip_code: this.vip_code,
          payPlatform: this.payPlatform,
          sys: this.tempCarInfo.sys,
        })
      );
    },

    //确认支付
    async payMoney() {
      //  如果不等于微信支付
      if (this.selectVal != "1") {
        console.log("未选择微信支付");
        return false;
      }
      if (await this.checkOrderStatus()) {
        this.$Toast({
          message: "该订单已支付，不能重新支付！",
        });
        return;
      }
      //支付提示
      this.$dialog({
        showConfirmButton: true,
        showCancelButton: true,
        message: `临时停车用户缴费成功后请您在${
          this.tempCarInfo.leftTime || 15
        }分钟内离场，超过${
          this.tempCarInfo.leftTime || 15
        }分钟则开始重新计费。`,
      }).then(
        /* 确认缴费 */
        async () => {
          if (
            this.payPlatform != 7 &&
            this.tempCarInfo.couponSet == 1 &&
            (this.$store.state.couponCheckedId.length >= 1 ||
              this.integralValue > 0)
          ) {
            await this.getTotalPayWidthCoupon();
            //积分项重置
            this.$store.commit("setIntegralToMoneyInStore", 0);
          } else {
            this.syncPay = this.tempCarInfo.totalFee;
          }

          await this.saveTempCarInfoToService();
        },
        /* 取消缴费 */
        () => {
          this.$Toast({
            message: "已取消支付！",
          });
        }
      );
    },

    //微信支付
    weixinPay(paramse) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.jsApiCall(paramse),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.jsApiCall(paramse));
          document.attachEvent(
            "onWeixinJSBridgeReady",
            that.jsApiCall(paramse)
          );
        }
      } else {
        that.jsApiCall(paramse);
      }
    },
    jsApiCall(paramse) {
      var that = this;
      if (paramse.jsonParams.appId == undefined) {
        that.$Toast({
          message: "获取支付信息失败",
        });
        return;
      }
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: paramse.jsonParams.appId, //公众号名称，由商户传入
          timeStamp: paramse.jsonParams.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: paramse.jsonParams.nonceStr, //随机串
          package: paramse.jsonParams.package,
          signType: paramse.jsonParams.signType, //微信签名方式：
          paySign: paramse.jsonParams.paySign, //微信签名
        },
        function (res) {
          if (res && res.err_msg == "get_brand_wcpay_request:ok") {
            that.goPayResult("ok");
          } else {
            that.goPayResult("failed");
          }
        }
      );
    },
  },

  beforeDestroy() {
    this.$store.commit("resetData");
    clearInterval(this.timer);
  },
  components: {
    coupon,
    payType,
  },
};
</script>

<style lang="less">
.ad_style {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}

.detailb .count_box .box_title {
  text-align: center;
}
.left_arrrow {
  height: 20px;
  width: 16px;
  margin-left: 10px;
}
.detailb {
  .pay-area {
    margin-top: 100px;
    // margin-bottom: 50px;
    .pay-title {
      display: flex;
      justify-content: space-between;
      .open-nNosense-pay,
      .open-nNosense-pay {
        color: #40413f;
        font-weight: bold;
        font-size: 28px;
      }
      .open-nNosense-pay {
        color: #2ec5b6;
      }
    }
  }
  .couponsUrl {
    text-align: center;
    color: #2ec5b6 !important;
    font-size: 28px;
    height: 100%;
    line-height: 100px;
  }

  .fixed-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background: #fff;
  }

  .btn_wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 700px;
  }

  .nopay {
    .detailP_wrapper {
      padding: 0 30px;

      .plate_num_show {
        position: relative;
        display: flex;
        align-items: center;
        .plates_num {
          font-size: 40px;
          font-weight: bold;
          color: #333333;
        }
      }

      .com_box_style {
        display: flex;
        justify-content: space-between;
        height: 100px;
        line-height: 100px;
        box-sizing: border-box;
        border-bottom: 1px solid #f6f6f6;
        font-size: 28px;
        color: #999;

        span {
          color: #333;
        }
      }
    }

    .count_box {
      height: 190px;
      box-sizing: border-box;
      border-bottom: 1px solid #f6f6f6;
      margin-bottom: 100px;
      display: flex;
      justify-content: space-around;
      text-align: center;
      .time_box {
        font-size: 50px;
        font-weight: bold;
        color: #333333;
        padding: 50px 0 29px;
        box-sizing: border-box;
      }

      .money_box {
        .time_box;
        color: #eb7a16;
      }

      .box_title {
        font-size: 26px;
        color: #999;
        text-align: center !important;
      }
    }

    .nopay_pic_box {
      width: 130px;
      height: 118px;
      background: url("../../assets/img/nopay_pic.png") no-repeat center
        center/130px;
      margin: 0 auto;
    }

    .nopay_txt {
      font-size: 34px;
      font-weight: bold;
      color: #2ec5b6;
      margin-top: 50px;
      text-align: center;
    }
  }
  padding: 20px 30px 0 30px;

  .detailP_wrapper {
    padding: 0 30px;

    .plate_num_show {
      position: relative;

      .plates_num {
        font-size: 40px;
        font-weight: bold;
        color: #333333;
      }
    }

    .com_box_style {
      .flex_between;
      height: 100px;
      line-height: 100px;
      box-sizing: border-box;
      border-bottom: 1px solid #f6f6f6;
      font-size: 28px;
      color: #999;
      .van-stepper--round .van-stepper__minus {
        color: #9999 !important;
        border: 1px solid #9999;
      }
      .van-stepper--round .van-stepper__plus {
        color: #9999 !important;
        border: 1px solid #9999;
        background-color: #fff;
      }

      span {
        color: #333;
      }
      .integral {
        flex-grow: 1;
        font-size: 28px;
        color: #2dc5b5;
        margin-left: 20px;
      }
    }
  }

  .count_box {
    height: 190px;
    box-sizing: border-box;
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-around;

    .time_box {
      font-size: 50px;
      font-weight: bold;
      color: #333333;
      padding: 50px 0 29px;
      box-sizing: border-box;
    }

    .money_box {
      .time_box;
      color: #eb7a16;
    }

    .box_title {
      font-size: 26px;
      color: #999;
    }
  }
  .no-mt {
    margin-bottom: 0 !important;
  }
  .mr-b {
    margin-bottom: 100px;
  }
}
</style>
