<template>
    <div class="coupon_box">
        <div class="coupon_box_wrapper">
            <div class="confirm_box">
                <p
                    class="coupon_cancel"
                    @click="closeCouponsBox(0)"
                >取消</p>
                <p class="coupon_tip">请选择优惠券</p>
                <p
                    class="coupon_confirm"
                    @click="closeCouponsBox(1)"
                >确认</p>
            </div>
            <div class="coupons">
                <van-checkbox-group v-model="$store.state.couponCheckedId">
                    <van-cell-group>
                        <van-cell
                            v-for="coupon in $store.state.couponsArray"
                            clickable
                            :key="coupon.id"
                            :title="coupon.content"
                            @click="toggle(coupon.id,coupon.content)"
                            
                        >

                            <van-checkbox
                                :name="coupon.id"
                                ref="checkboxes"
                                slot="right-icon"
                                checked-color="#2ec5b6"
                                age='123'
                            >
                              <!--    <img
                                    slot="icon"
                                    slot-scope="props"
                                    :title="props.age"
                                    :src="$store.state.couponCheckedId.indexOf(props.name)>-1?'../../assets/img/selected.png':'../../assets/img/normal.png'"
                                >-->
                            </van-checkbox>

                        </van-cell>
                    </van-cell-group>
                </van-checkbox-group>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        checkedCouponID: '',
    }),
    created() {},
    updated() {
        //有优惠券必须选择一张
        if (
            this.$store.state.couponsArray.length >= 1 &&
            this.$store.state.couponCheckedId.length == 0
        ) {
            this.$store.commit(
                'setCouponCheckedId',
                this.$store.state.couponsArray[0].id
            );
            this.$store.commit('setCouponText');
            this.$emit('getCharge');
        }
    },
    methods: {
        toggle(num, content) {
            this.$store.commit('setCouponCheckedId', num);
            this.$store.commit('setCouponText');
            if (this.$store.state.couponCheckedId.length >= 1) {
                this.$emit('getCharge');
            }
        },
        //type=0标识为取消，type=1标识为确认
        closeCouponsBox(type) {
            this.$store.commit('closeCouponBox');
            /*  this.$store.commit('setCouponText'); */
            this.$store.commit('setDiscountNum');
        },
    },
};
</script>

<style lang="less" scoped>

.coupon_box {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.3);

    .coupon_box_wrapper {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #fff;
        width: 100%;
        font-size: 30px;

        .confirm_box {
            .flex_between;
            padding: 0 56px;
            box-sizing: border-box;
            line-height: 100px;
            height: 100px;
            width: 100%;
            border-bottom: 1px solid #f6f6f6;

            .coupon_cancel {
                color: #999;
            }

            .coupon_cancel {
                color: #333;
            }

            .coupon_confirm {
                color: @defCol;
            }
        }

        .coupons {
            padding: 0 40px;
            box-sizing: border-box;
            max-height: 230px;
            overflow: auto;
            .van-cell {
                height: 60px;
                padding: 0px 8px;
                vertical-align: middle;
                .van-cell__title {
                    line-height: 60px;
                    vertical-align: middle;
                }
            }

            .coupon_itme {
                display: flex;
                width: 100%;
                border-bottom: 1px solid #f6f6f6;
            }
        }
    }
}
</style>
