import axios from 'axios';
import Vue from 'vue';
import {
    objToQs,
    qsToObj
} from '../config/utils';


//逃费页面使用
Vue.prototype.$axios = axios;

const theURL = {
    getTip: '/Api/TempDesc(get)',
    searchTemplateCarParkingInfo: '/Api/tempCardInfo(post)',
    commitPayParkingInfo: '/Api/tempCardSave(post)',
    checkOrderStatus: '/Api/checkOrder(post)',
    loginByWechat: '/Api/WechatLogin(get)',
    searchOrderByOpenID: '/Api/PayOrderDetail(get)',
    areaSupportInvoice: '/Api/WhiteList(get)',
    searchCoupons: '/Api/tempCoupons(post)',
    countPayWithCoupon: '/Api/tempRechargeInfo(post)',
    getCatPayList: '/Api/WhiteList(get)',
    getCode: '/Invoice/getInvoiceCode(get)',
    invoiceStatus: '/Invoice/getInvoiceState(get)',
    addAutoPayCarNo: '/Api/addAutoPayCarNo(post)',
    checkAutoPay: '/Api/checkAutoPay(get)', // 检测车牌无感支付状态
    MonthlyCardInfo: '/Api/MonthCardInfo(post)', //查询月卡信息
    saveMontlyCard: '/Api/monthCardSave(post)', //保存月卡信息
    monthlyCardDelay: '/Api/monthCardDelay(post)', //月卡延期
    apiorder: '/Api/WxPayParking(get)',
    GetCarCardInfo:'/Api/GetCarCardInfo(post)',
    GetUnionPay:'/Api/unionPay(post)',
    GetUnionPayRes:'/Api/unionPayResult(post)',
    IsUnionArea:'/Api/IsUnionArea(get)',
};

const Api = new Proxy(theURL, {
    get(target, key, receiver) {
        if (/^\//.test(key)) {
            let method = key.match(/\((.*?)\)/)[1];
            let url = key.slice(0, key.indexOf('('));
            return (params = {}) => {
                url = !objToQs(params) ? url : `${url}?${objToQs(params)}`;
                params = {};
                return axios[method](url, params);
            };
        }
    },
});

//判断该车场是否接入银联支付
let IsUnionArea = (params) =>Api[theURL.IsUnionArea](params)

//查询银联支付结果
let GetUnionPayRes = (params) =>Api[theURL.GetUnionPayRes](params)

//调起银联支付链接
let GetUnionPay = (params) =>Api[theURL.GetUnionPay](params)

//获取提示文案
let getTheTips = (params) => Api[theURL.getTip](params);
let getapiOrder= (params) => Api[theURL.apiorder](params);

//查询停车信息
let postSearchCarInfo = (params) => Api[theURL.searchTemplateCarParkingInfo](params);

//上报缴费信息
let postSaveCarInfo = (params) => Api[theURL.commitPayParkingInfo](params);

//查询订单状态
let postCheckOrderStatus = (params) => Api[theURL.checkOrderStatus](params);

//获取缴费记录
let getPayRecorders = (params) => Api[theURL.searchOrderByOpenID](params);

//获取支持发票的车场
let getInvoiceArea = (params) => Api[theURL.areaSupportInvoice](params);

//获取优惠券
let postCoupons = (params) => Api[theURL.searchCoupons](params);

//获取优惠兑换后的价格
let postRechargePay = (params) => Api[theURL.countPayWithCoupon](params);

//获取发票code
let getInvoiceCode = (params) => Api[theURL.getCode](params);

//获取发票状态
let getInvoiceStatus = (params) => Api[theURL.invoiceStatus](params);

//查询月卡
let postMonthlyCardInfo = (params) => Api[theURL.MonthlyCardInfo](params);

//上报月卡信息
let postSaveMonthlyCard = (params) => Api[theURL.saveMontlyCard](params);

//月卡延期
let postMonthlyCardDelay = (params) => Api[theURL.monthlyCardDelay](params);

//获取车辆类型
let getCarCardInfo = (params) => Api[theURL.GetCarCardInfo](params)

//微信静默授权获取openId
let wechatLogin = (href) => {
    let url = encodeURIComponent(href);
    Api[theURL.loginByWechat]({
        redirectUrl: url
    }).then((res) => {
        console.log(res)
        if (res.data.url) {
            window.location.replace(res.data.url + '?f_time=' + new Date().getTime());
        }
    });
};

let getCatList = (params) => Api[theURL.getCatPayList](params);

// 添加无感车牌
let postAddAutoPayCarNo = (params) => Api[theURL.addAutoPayCarNo](params);
// 检测无感支付状态
let postCheckAutoPay = (params) => Api[theURL.checkAutoPay](params);
export {
    Api,
    theURL,
    postCheckAutoPay,
    postSearchCarInfo,
    postSaveCarInfo,
    postCheckOrderStatus,
    getPayRecorders,
    getInvoiceArea,
    postCoupons,
    postRechargePay,
    wechatLogin,
    getTheTips,
    getCatList,
    getInvoiceCode,
    getInvoiceStatus,
    postMonthlyCardInfo,
    postSaveMonthlyCard,
    postMonthlyCardDelay,
    postAddAutoPayCarNo,
    getapiOrder,
    getCarCardInfo,
    GetUnionPay,
    GetUnionPayRes,
    IsUnionArea
};