<template>
  <div class="monthly-card" v-if="tempCarInfo.info">
    <div class="wrapper">
      <div class="plate_num_show com_box_style dot">
        <span class="plates_num" v-if="tempCarInfo.info.carNo">{{
          templateShowStyle(tempCarInfo.info.carNo)
        }}</span>
      </div>
      <div class="com_box_style">
        商户名称
        <span class="parking_lot">{{ tempCarInfo.info.areaName }}</span>
      </div>
      <div class="com_box_style">
        当前月卡到期时间
        <span class="parking_lot">{{ tempCarInfo.startTime }}</span>
      </div>

      <div class="com_box_style">
        续期开始时间
        <span class="time_start">{{ tempCarInfo.payStartTime() }}</span>
      </div>
      <div class="com_box_style">
        续期到期时间 <span class="parking_time">{{ dutoTime }}</span>
      </div>

      <div class="com_box_style">
        收费标准
        <span class="current_time">{{
          tempCarInfo.payStandard + "元/月"
        }}</span>
      </div>
      <!-- <div class="com_box_style tips_desc">
                {{tips}}
            </div> -->

      <!-- 时长，费用 -->
      <div class="count_box">
        <div>
          <p class="time_box">
            <van-stepper v-model="stepperValue" theme="" integer :step="stepInteger" min="1" :disable-input="noInput" :disable-plus="plusAble" :max="maxValue" default-value="0" button-size="22" input-width="40px" @change="stepperValChange" />
          </p>
          <p class="box_title">续期月数</p>
        </div>
        <div>
          <p class="money_box"><span class="money_symbol">￥</span>{{ pay }}</p>
          <p class="box_title">续期费用</p>
        </div>
      </div>
      <!-- 时长，费用 -->
    </div>
    <div v-for="item in tempCarInfo.lists" :key="item.id">
      {{ item.id }}=>{{ item.content }}
    </div>

    <!-- 缴费确认 -->
    <div>
      <van-button type="default" class="btn1" @click="payMoney">续期</van-button>
    </div>
    <!-- 缴费确认 -->
  </div>
</template>
<script>
import {
  formatTime,
  formatTimeNew,
  templateShowStyle,
} from "../../config/utils.js";
import monthyCard from "./monthlyCard";
export default {
  data: () => ({
    title: "月卡缴费",
    tips: "续期时间为 当月25号至下月5号 ，其余时间续期请前往物业服务中心办理，谢谢！",
    tempCarInfo: {}, //月卡信息
    //计步器
    stepperValue: 1,
    noInput: true,
    plusAble: false,
    maxValue: 12,
    stepInteger: 1,
    dutoTime: "", //续费到期时间
    pay: "",
    monthNum: 1,
    newArea: "",
  }),
  async created() {
    // 放在session  关闭可以清除,小程序支付需要

    if (this.$route.query.source) {
      sessionStorage.setItem("source", this.$route.query.source);
      sessionStorage.setItem("payOpenId", this.$route.query.payOpenId);
    }

    this.openId =
      this.$route.query.openId || sessionStorage.getItem("openId") || "";
    //没有获取到openId，使用微信静默授权登录

    !this.openId && this.$http.wechatLogin(window.location.href);

    this.getQueryData();
    this.tempCarInfo = monthyCard;
    if (!this.tempCarInfo.info.carNo) {
      let { code, data } = await this.tempCarInfo.postMonthlyCardInfoFromSev({
        carNo: this.plate, //粤B5W5S5 粤B106RN
        area: this.area,
        openId: this.openId,
      });
      if (code != 200) return;
      this.tempCarInfo.initData(data);
    }
    this.dutoTime = this.tempCarInfo.dutoTime();
    this.pay = this.tempCarInfo.totalPay();
  },

  methods: {
    formatTime,
    formatTimeNew,
    templateShowStyle,
    getQueryData() {
      this.plate = this.$route.query.plate;
      this.area = this.$route.query.area;
      this.openId = this.$route.query.openId;
      this.newArea = this.$route.query.area;
    },

    stepperValChange(val) {
      this.monthNum = val;
      this.dutoTime = this.tempCarInfo.dutoTime(val);
      this.pay = this.tempCarInfo.totalPay(val);
    },

    async payMoney() {
      try {
        if (this.tempCarInfo.isMore3Days && this.newArea != "HZDD01") {
          this.$Toast({
            message: "月卡过期超过三天，请到物业管理处续费！",
          });
          return false;
        } else if (this.newArea == "HZDD01" && this.tempCarInfo.isMore10Days) {
          this.$Toast({
            message: "月卡过期超过十天，请到物业管理处续费！",
          });
          return false;
        }
        let { code, data } = await this.$http.postCheckOrderStatus({
          orderSn: this.tempCarInfo.payOrderSn,
        });
        //检查订单状态
        if (code == 200) {
          this.saveTempCarInfoToService();
        }
      } catch {}
    },

    async saveTempCarInfoToService() {
      let res = await this.tempCarInfo.postSaveCardInfoFromSev({
        telephone: this.phone || "",
        carNo: this.plate,
        orderSn: this.tempCarInfo.payOrderSn,
        month: this.monthNum,
        money: this.pay,
        beginTime: this.tempCarInfo.payStartTime(), //月卡到期时间+1天
        endTime: this.dutoTime,
        area: this.area,
        sys: this.tempCarInfo.info.sys,
        payPlatform: 1,
      });
      /*  console.log(res, '上报结果'); */
      if (res.code == 200) {
        //保存上报后的数据，给结果页面应用
        this.saveDataTolocal();
        // 上报成功进行支
        this.callThePay();
      }
    },

    //调起支付
    async callThePay() {
      /*
          先判断车场，对应车场调起银联支付接口，获取银联支付链接
        */
      let area = this.area;
      let IsUnionAreaRes = await this.$http.IsUnionArea({
        area: area,
      });

      // 判断是否小程序环境
      var source = sessionStorage.getItem("source");
      var payOpenId = sessionStorage.getItem("payOpenId");
      var url = window.location.href.split("#/")[0] + "#/mpPay";

      url = encodeURIComponent(url);

      if (source && source == "cocogo") {
        //2023.7.5
        //payOpenId为 支付走小程序分支 时所需要的参数，实际为换取openId的code，由需要打开H5支付的小程序拼接提供
        location.href = `./Api/WxPayParking?orderSn=${
          this.tempCarInfo.payOrderSn
        }&payAmount=${
          this.pay
        }&t=${new Date().getTime()}&source=cocogo&backurl=${url}&payOpenId=${payOpenId}`;
      } else {
        if (IsUnionAreaRes.data.state === 1) {
          let res = await this.$http.GetUnionPay({
            orderSn: this.tempCarInfo.payOrderSn,
            payAmount: this.pay,
            t: new Date().getTime(),
          });
          console.log(res);
          console.log(res.data.data.pay_url);
          localStorage.setItem("unionPay", true);
          location.replace(res.data.data.pay_url);
        } else {
          location.href = `./Api/WxPayParking?orderSn=${
            this.tempCarInfo.payOrderSn
          }&payAmount=${this.pay}&t=${new Date().getTime()}`;
        }
      }
    },

    // 保存信息到本地，用于结果页面
    saveDataTolocal() {
      localStorage.setItem(
        "resultData",
        JSON.stringify({
          plate: this.plate,
          money: this.pay,
          isMonthlyCard: 1,
          startTime: this.tempCarInfo.startTime || "",
          orderSn: this.tempCarInfo.payOrderSn,
          parkTime: this.tempCarInfo.serviceTime || "",
          area: this.area,
          areaName: this.tempCarInfo.info.areaName,
          payType: "微信支付",
          openId: this.openId,
          payPlatform: this.payPlatform || "",
          sys: this.tempCarInfo.info.sys || "",
        })
      );
    },

    goPayResult(showLogin) {
      let that = this;
      that.$router.push({
        path: "/parkPayResult",
        query: {
          result: showLogin,
        },
      });
    },

    //微信支付
    weixinPay(paramse) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.jsApiCall(paramse),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.jsApiCall(paramse));
          document.attachEvent(
            "onWeixinJSBridgeReady",
            that.jsApiCall(paramse)
          );
        }
      } else {
        that.jsApiCall(paramse);
      }
    },
    jsApiCall(paramse) {
      var that = this;
      if (paramse.jsonParams.appId == undefined) {
        that.$Toast({
          message: "获取支付信息失败",
        });
        return;
      }
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: paramse.jsonParams.appId, //公众号名称，由商户传入
          timeStamp: paramse.jsonParams.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: paramse.jsonParams.nonceStr, //随机串
          package: paramse.jsonParams.package,
          signType: paramse.jsonParams.signType, //微信签名方式：
          paySign: paramse.jsonParams.paySign, //微信签名
        },
        function (res) {
          if (res && res.err_msg == "get_brand_wcpay_request:ok") {
            that.goPayResult("ok");
          } else {
            that.goPayResult("failed");
          }
        }
      );
    },
  },
};
</script>
<style lang="less">
//续费开始时间icon

.monthly-card {
  padding: 30px;
  .wrapper {
    padding: 0 25px;
    //stepper 样式自定义 #2ec5b6
    .van-stepper__minus {
      height: 30px !important;
      width: 30px !important;
      background-color: #fff !important;
      border-radius: 6px !important;
      border: 1px solid #2ec5b6 !important;
    }
    .van-stepper__plus {
      height: 30px !important;
      width: 30px !important;
      background-color: #fff !important;
      border-radius: 6px !important;
      border: 1px solid #2ec5b6 !important;
    }
    .van-stepper__plus::before,
    .van-stepper__plus::after {
      background-color: #2ec5b6 !important;
    }
    .van-stepper__minus::before {
      background-color: #2ec5b6 !important;
    }
    .stepper__minus::before {
      background-color: #dedede !important;
    }
    //禁用时
    .van-stepper__minus--disabled {
      border: 1px solid #dedede !important;
    }
    .van-stepper__minus--disabled::before {
      background-color: #dedede !important;
    }
    .van-stepper__plus--disabled {
      border: 1px solid #dedede !important;
    }
    .van-stepper__plus--disabled::after,
    .van-stepper__plus--disabled::before {
      background-color: #dedede !important;
    }
    .van-stepper__input {
      background-color: #fff !important;
      font-size: 26px !important;
      width: 80px !important;
    }
    .time_start {
      position: relative;
    }
    .time_start::before {
      position: absolute;
      left: -54px;
      top: 4px;
      content: "";
      width: 32px;
      height: 30px;
      background: url("../../assets/img/monthlycard_icon.png") no-repeat center/
        32px 30px;
    }

    .com_box_style {
      display: flex;
      justify-content: space-between;
      padding: 30px 0;
      line-height: 1.5em;
      border-bottom: 1px solid #f6f6f6;
      font-size: 28px;
      color: #999;
      span {
        color: #333;
      }
    }
    .plate_num_show {
      position: relative;
      display: flex;
      align-items: center;
      .plates_num {
        font-size: 40px;
        font-weight: bold;
        color: #333333;
      }
    }
    .count_box {
      height: 190px;
      box-sizing: border-box;
      border-bottom: 1px solid #f6f6f6;
      margin-bottom: 100px;
      display: flex;
      justify-content: space-around;
      text-align: center;
      .time_box {
        font-size: 50px;
        font-weight: bold;
        color: #333333;
        padding: 50px 0 29px;
        box-sizing: border-box;
        .van-stepper__input {
          height: 30px !important;
        }
      }

      .money_box {
        .time_box;
        color: #eb7a16;
      }

      .box_title {
        font-size: 26px;
        color: #999;
        text-align: center !important;
      }
    }
    .tips_desc {
      color: #cccccc;
    }
  }
}
</style>