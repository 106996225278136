<template>
    <div class="select_cont">
        <div class="province_select_box">
            <div
                v-if="$store.state.theLetterIndex==0"
                class="select_wrap"
            >
                <span
                    class="province_text_cont key_hover"
                    v-for='province in provinceList'
                    :key='province'
                    @click="getKeyboardLetter"
                >{{province}}</span>
                <span
                    class="province_text_cont"
                    :style="{opacity:0}"
                ></span>
                <span
                    class="province_text_cont"
                    :style="{opacity:0}"
                ></span>
                <span
                    class="province_text_cont"
                    :style="{opacity:0}"
                ></span>
                <span
                    class="province_text_cont"
                    :style="{opacity:0}"
                ></span>
            </div>
            <div
                v-if="$store.state.theLetterIndex>=1"
                class="select_wrap"
                ref='keyboard'
            >
                <span
                    class="the_letter_style key_hover"
                    v-for='(letter,index) in cityList'
                    :key='letter'
                    :style="{color:($store.state.theLetterIndex==1 && index<=9 ) || ($store.state.theLetterIndex==1 && index>33&&index<=38)||($store.state.theLetterIndex>=2 && $store.state.theLetterIndex!=6&&index>33&&index<=38 )?'#dedede':''}"
                    @click="getKeyboardLetter"
                >{{letter}}</span>
                <span
                    class="clear_letter the_letter_style"
                    @click="clearInputText"
                >x</span>
            </div>
        </div>
        <div class="city_select_box"></div>
        <div class="select_key"></div>
    </div>
</template>

<script>
export default {
    data: () => ({
        provinceList: [
            '京',
            '沪',
            '津',
            '粤',
            '浙',
            '苏',
            '冀',
            '川',
            '渝',
            '鄂',
            '闽',
            '陕',
            '鲁',
            '湘',
            '辽',
            '皖',
            '赣',
            '豫',
            '桂',
            '晋',
            '云',
            '贵',
            '黑',
            '吉',
            '琼',
            '甘',
            '蒙',
            '新',
            '青',
            '宁',
            '藏',
        ],
        cityList: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            0,
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'J',
            'K',
            'L',
            'M',
            'N',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
            '学',
            '军',
            '警',
            '港',
            '澳',
        ],
        letterIndex: null,
    }),
    created() {
        this.letterIndex = this.$store.state.theLetterIndex;
    },
    methods: {
        getKeyboardLetter(e) {
            //车牌第二位，禁止数字和特殊字符输入
            if (
                this.$store.state.theLetterIndex === 1 &&
                !/[A-Z]/.test(e.target.innerText)
            )
                return;
            //车牌后5位，禁止数字和特殊字符输入
            if (
                /[23457]/.test(this.$store.state.theLetterIndex) &&
                !/[A-Z0-9]/.test(e.target.innerText)
            )
                return;
            this.$store.commit('setPlate', e.target.innerText);
        },
        //删除车牌号
        clearInputText() {
            this.$store.commit('setPlate', null);
        },
    },
};
</script>

<style lang="less" scoped>
.key_hover:hover {
    background-color: @defCol;
    color: #fff;
}
.the_letter_style {
    height: 86px;
    line-height: 86px;
    text-align: center;
    width: 60px;
    box-sizing: border-box;
    border: 2px solid #dedede;
    border-radius: 10px;
    margin: 10px 6px;
}

.select_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding-top: 2px;
    .province_text_cont {
        display: flex;
        text-align: center;
        width: 86px;
        height: 86px;
        line-height: 86px;
        background: #fefefe;
        border: 2px solid #dedede;
        border-radius: 10px;
        box-sizing: border-box;
        display: inline-block;
        text-align: center;
        margin: 9px 10px;
        &:hover {
            background-color: @defCol;
            color: #fff;
        }
    }

    .last_itme {
        margin-right: auto;
        margin-left: 10px;
    }
    .clear_letter {
        background-color: #dedede;
        color: #fff;
        font-weight: bold;
        &:hover {
            background-color: @defCol;
        }
    }
}
</style>
