import Vue from 'vue';
import VueRouter from 'vue-router';
import receiveRedEnvelopes from '../views/redEnvelope/receiveEnvelopes.vue';
import failedRedEnvelopes from '../views/redEnvelope/failedRedEnvelopes.vue';
import resultsPage from '../views/payResult/resultsPage.vue';
import senselessPayment from "../views/senselessPayment/senselessPayment.vue"
import mpPay from "../views/mpPay/mpPay.vue"

import {
    home,
    detail,
    result,
    payHistory,

} from '../views';



//月卡续费
import monthlyCardPayment from '../views/monthlyCardPayment';

Vue.use(VueRouter);

export default new VueRouter({
    routes: [{
            path: '/',
            name: 'home',
            component: home,
            meta: {
                pageTitle: '星河智慧停车'
            },
        },
        {
            path: '/parkSearchCard',
            name: 'home',
            component: home,
            meta: {
                pageTitle: '星河智慧停车'
            },
        },
        {
            path: '/useDetail',
            name: 'detail',
            component: detail,
            meta: {
                pageTitle: '缴费详情'
            },
        },
        {
            path: '/escapeFee',
            name: 'detail',
            component: detail,
            meta: {
                pageTitle: '临停缴费',
            },
        },

        {
            path: '/parkPayResult',
            name: 'result',
            component: result,
            meta: {
                pageTitle: '支付结果'
            },
        },
        {
            path: '/transfer',
            name: 'transfer',
            component: result,
            meta: {
                pageTitle: '中转页'
            },
        },
        {
            path: '/paymentRecords',
            name: 'payhistory',
            component: payHistory,
            meta: {
                pageTitle: '缴费记录'
            },
        },
        {
            path: '/receiveRedEnvelopes',
            name: 'receiveRedEnvelopes',
            component: receiveRedEnvelopes,
            meta: {
                pageTitle: '领取红包'
            },
        },
        {
            path: '/failedRedEnvelopes',
            name: 'failedRedEnvelopes',
            component: failedRedEnvelopes,
            meta: {
                pageTitle: '领取红包失败'
            },
        },
        {
            path: '/resultsPage',
            name: 'resultsPage',
            component: resultsPage,
            meta: {
                pageTitle: '结果反馈',
            },
        },
        {
            path: '/monthlycard',
            name: 'monthlycard',
            component: monthlyCardPayment,
            meta: {
                pageTitle: '月卡续期',
            },
        },
        {
            path: '/senselessPayment',
            name: 'senselessPayment',
            component: senselessPayment,
            meta: {
                pageTitle: '开通无感支付',
            },
        },
        {
                        path: '/mpPay',   // 跳转小程序带参页面
                       name: 'mpPay',
                        component: mpPay,
                       meta: {
                           pageTitle: '去支付',
                       },
                   },
            
    ],
});