<template>
  <div class="home_page">
    <div class="show_box">
      <!-- 输入框 -->
      <div class="input_box">
        <h3>
          <p>
            <img src="../../assets/img/plates_text.png" alt="" />
          </p>
          <div class="recoder_class">
            <span @click="goToPaymentHistory">缴费记录 </span>
            <span style="margin-left:4px">></span>
          </div>
        </h3>
        <plate-input v-if="$store.state.thePlate.length <= 8"> </plate-input>

        <!-- 无牌车展示 -->
        <div class="noPlateCarBox" v-if="$store.state.thePlate.length > 8">
          {{ templateShowStyle($store.state.thePlate.join("")) }}
        </div>
        <!-- 无牌车展示 -->
      </div>
      <!-- 输入框 -->

      <!-- 历史车牌 -->
      <div class="input_history">
        <div class="history_tit">历史记录</div>
        <div>
          <span class="history_label" v-for="userplate in usersPlates" :key="userplate" @click="historyClick">{{
            userplate
          }}</span>
        </div>
      </div>
      <!-- 历史车牌 -->

      <!-- 确认按钮 -->
      <div>
        <van-button type="default" class="btn1" @click="goToPayDetail">临停缴费</van-button>
      </div>
      <!-- 确认按钮 -->

      <!-- 月卡按钮 -->
      <div>
        <van-button type="default" class="btn1" @click="monthyCardDetail" :style="{ marginTop: '20px' }"
          >月卡缴费</van-button
        >
      </div>
      <!-- 月卡按钮 -->

      <!-- 缴费说明 -->
      <div class="tips">
        <div class="history_tit tip_tit" @click="showTips">缴费说明</div>
      </div>
      <!-- 缴费说明 -->
    </div>

    <!-- ad  -->
    <!-- ad placeholder -->
    <div v-if="false" style="height: 190px"></div>
    <div v-if="false" class="ad_style">
      <a href="http://xinghe2.fl.auwinner.com">
        <img src="../../assets/img/ic_aa_main.png" alt="xinghe" style="width: 100%" />
      </a>
    </div>
    <!-- ad end -->

    <div v-if="$store.state.keyboardShow" class="key_board_box">
      <div class="click_bar" @click="closeKeyboard"></div>
      <text-select></text-select>
    </div>
  </div>
</template>

<script>
import { qsToObj, getUrlVars, isCarLicense, getCurrentTime, isWeiXin, templateShowStyle } from "../../config/utils";
import { plateInput, textSelect } from "../../components";
import { mapState } from "vuex";
import monthlyCard from "../monthlyCardPayment/monthlyCard";

export default {
  data: () => ({
    openId: "", //用户标识
    area: "", //车场编码
    vip_code: "", //vip码
    equipCode: "", //设备编号
    key: "",
    textarea: "", //提示信息
    usersPlates: [], //用户输入过的车牌
    plate: "",
    defaultTips:
      "1. 临时停车用户缴费成功后请您在15分钟内离场，超过15分钟则开始重新计费，发票领取凭（缴费信息）至中央收费处领取;\r2. 临牌车、无牌车、特殊车牌及搜索不到车辆信息的车主请至中央收费处缴费。",
  }),
  computed: {
    ...mapState(["tempCarInfo"]),
  },
  created() {

             // 放在session  关闭可以清除,小程序支付需要
 
 
         if(this.$route.query.source){
         sessionStorage.setItem('source',this.$route.query.source)
          sessionStorage.setItem('payOpenId',this.$route.query.payOpenId)
         }

    this.initParams();
    if (this.plate.length <= 8) {
      this.getInputPlate();
    }
  },
  mounted() {
    this.getTipsText();
  },
  methods: {
    templateShowStyle,
    /* 关闭输入键盘 */
    closeKeyboard() {
      this.$store.commit("closeKeyboard");
    },

    /* 弹出提示信息 */
    showTips() {
      this.$dialog({
        messageAlign: "left",
        message: this.textarea,
        className: "cofirm_color",
      });
    },

    /* 获取历史车牌 */
    getInputPlate() {
      let usersPlates = JSON.parse(localStorage.getItem("userPlates") || "[]");
      usersPlates.length > 0 && (this.usersPlates = usersPlates);
      if (this.usersPlates[0] && this.usersPlates[0].length >= 7) {
        this.$store.state.isNewEnergy = this.usersPlates[0].length === 7 ? true : false;
        /* 将历史 */
        this.$store.commit("getHistoryToPlateInput", usersPlates[0].split(""));
      }
    },

    /* 保存输入车牌 最多3个记录，新添加在最前面 */
    saveInputPlate() {
      let currentPlate = this.$store.state.thePlate.join("");
      let userPlates = JSON.parse(localStorage.getItem("userPlates") || "[]");

      if (userPlates.indexOf(currentPlate) == -1) {
        userPlates.unshift(currentPlate);
        userPlates.length >= 3 && (userPlates.length = 3);
        localStorage.setItem("userPlates", JSON.stringify(userPlates));
      } else {
        userPlates.splice(userPlates.indexOf(currentPlate), 1);
        userPlates.unshift(currentPlate);
        localStorage.setItem("userPlates", JSON.stringify(userPlates));
      }
    },

    /* 历史车牌点击赋值 */
    historyClick(e) {
      let historyPlate = e.target.innerText.split("");
      /* isNewEnergy 设定车牌显示样式 */
      this.$store.state.isNewEnergy = historyPlate.length === 7 ? true : false;
      /* 将历史 */
      this.$store.commit("getHistoryToPlateInput", historyPlate);
    },

    /* 获取车牌查询参数 */
    initParams() {
      this.area = this.$route.query.area || qsToObj()["area"] || "";
      this.openId = this.$route.query.openId || qsToObj()["openId"] || sessionStorage.getItem("openId") || "";

      //没有获取到openId，使用微信静默授权登录
      !this.openId && this.$http.wechatLogin(window.location.href);
      sessionStorage.setItem("openId", this.openId);

      this.plate =
        this.$route.query.plate || this.$route.query.No || this.$route.query.carNo || qsToObj()["plate"] || "";
      this.vip_code = this.$route.query.vip_code || qsToObj()["vip_code"] || "";

      this.equipCode = this.$route.query.equipCode || qsToObj()["equipCode"] || "";
      this.key = this.$route.query.key || qsToObj()["key"] || "";

      if (this.plate) {
        let plates = this.plate.split("");
        this.$store.state.isNewEnergy = this.plate.length === 7 ? true : false;
        /* 将历史 */

        this.$store.commit("getHistoryToPlateInput", plates);

        /* url有车牌直接跳到详情 */
        this.goToPayDetail();
      }
    },

    /* 路由跳转缴费记录页面 */
    goToPaymentHistory() {
      // this.$router.push({
      //   path: "/paymentRecords",
      //   query: {
      //     area: this.area,
      //     openId: this.openId,
      //   },
      // });
      const phone = this.$route.query.phone ? this.$route.query.phone : ''
      const source = this.$route.query.source ? this.$route.query.source : ''
      console.log(this.openId);
      location.href = `https://parks.chngsl.com/#/payHistoryList?openId=${this.openId}&phone=${phone}&source=${source}`;
      // location.href = `http://localhost:8082/#/payHistoryList?openId=${this.openId}&phone=${15019215621}&source=${source}`;
      // location.href = `http://localhost:8082/#/payHistoryList?`;
    },

    /* 获取提示文案 */
    async getTipsText() {
      try {
        let res = await this.$http.getTheTips({
          area: this.area,
        });
        this.textarea = res.data ? res.data : this.defaultTips;
      } catch {
        this.textarea = this.defaultTips;
      }
    },

    /* 确认缴费进入缴费详情页面 */
    async goToPayDetail() {
      this.closeKeyboard();
      if (!isWeiXin()) {
        this.$Toast({
          message: " 目前仅支持微信支付，请使用微信浏览器打开 ",
          duration: 2000,
        });
        return;
      }
      // 校验车牌
      if (!this.verifyPlate()) {
        this.$dialog({
          message: "请输入正确的车牌信息！",
        });
        return;
      }
      if (!this.openId) return;
      //查询车牌并存到store
      await this.$store.dispatch("searchCarInfo", {
        carNo: this.$store.state.thePlate.join(""),
        area: this.area,
        openId: this.openId,
        equipCode: this.equipCode,
        key: this.key,
      });
      if (!this.tempCarInfo.carNo) {
        return;
      }
      //进入详情页
      this.goToDetail(this.tempCarInfo.group);

      //保存输入车牌到本地
      if (this.$store.state.thePlate.length <= 8) {
        this.saveInputPlate();
      }
    },

    /* 校验车牌 */
    verifyPlate() {
      return isCarLicense(this.$store.state.thePlate.join(""));
    },

    /* 进入详情 */
    goToDetail(detailType) {
      /* bs商业 pd产业  */
      this.$router.push({
        path: "/useDetail",
        query: {
          plate: this.$store.state.thePlate.join(""),
          area: this.area,
          dtype: detailType,
          openId: this.openId,
          vip_code: this.vip_code,
          equipCode: this.equipCode,
          key: this.key,
        },
      });
    },

    /* 月卡缴费 */
    async monthyCardDetail() {
      this.closeKeyboard();
      if (!isWeiXin()) {
        this.$Toast({
          message: " 目前仅支持微信支付，请使用微信浏览器打开 ",
          duration: 2000,
        });
        return;
      }
      // 校验车牌
      if (!this.verifyPlate()) {
        this.$dialog({
          message: "请输入正确的车牌信息！",
        });
        return;
      }
      if (!this.openId) return;
      let plate = this.$store.state.thePlate.join("");
      let { code, data } = await monthlyCard.postMonthlyCardInfoFromSev({
        carNo: plate, //粤B5W5S5 粤B106RN
        area: this.area,
        openId: this.openId,
      });
      if (code != 200) return;

      //保存输入车牌到本地
      if (this.$store.state.thePlate.length <= 8) {
        this.saveInputPlate();
      }
      monthlyCard.initData(data);
      this.$router.push({
        path: "/monthlycard",
        query: {
          plate: plate,
          area: this.area,
          openId: this.openId,
        },
      });
    },
  },
  components: {
    plateInput,
    textSelect,
  },
};
</script>

<style lang="less">
.home_page {
  background-color: white;

  .ad_style {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
  }

  .recoder_class {
    display: flex;
    align-items: center;
  }

  .van-toast {
    min-width: 80% !important;
  }

  .searchCar {
    min-width: 80% !important;
  }

  .show_box {
    padding: 0 30px;

    .history_tit {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 38px;
    }

    .input_box {
      height: 320px;
      width: 100%;
      background: url("../../assets/img/bg.png") no-repeat right bottom/384px 320px;

      h3 {
        .flex_between;
        height: 32px;
        padding-top: 64px;
        font-size: 27px;
        color: @defCol;

        p {
          height: 32px;
          vertical-align: middle;

          img {
            height: 100%;
          }
        }
      }
    }

    .input_history {
      .history_label {
        display: inline-block;
        width: 200px;
        height: 60px;
        margin: 10px;
        line-height: 60px;
        text-align: center;
        background-color: #f6f6f6;
        border-radius: 10px;
        margin-bottom: 60px;
        cursor: pointer;
        color: #9a9a9a;

        &:hover {
          background-color: @defCol;
          color: #fff;
        }
      }
    }

    .tips {
      display: flex;
      justify-content: center;
      margin-top: 60px;

      .tip_tit {
        color: #999;
        text-decoration: underline;
      }
    }

    .tips_content > p {
      font-size: 24px;
      font-family: PingFang SC;
      color: #666666;
      line-height: 44px;
    }
  }

  .key_board_box {
    position: fixed;
    bottom: 0;
    max-height: 630px;
    padding-bottom: 30px;
    width: 100%;
    background: #fcfcfc;

    .click_bar {
      height: 60px;
      border-top: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      background: url("../../assets/img/down_arrow.png") no-repeat center center/40px;
    }
  }
}
</style>
