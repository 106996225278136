import {postSearchCarInfo} from '../api/index.js';

const couponActions = {
    /* 查询优惠券 */
    async searchCarInfo(store, {carNo, openId, area, equipCode, key}) {
        try {
            let {data} = await postSearchCarInfo({
                area: area,
                carNo: carNo,
                openId: openId,
                equipCode: equipCode,
                key: key,
            });

            await store.commit('setTempCarInfo', data || {});
        } catch {
        }
    },
};

export {couponActions};
