<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data: () => ({}),
    created() {},
    methods: {},
    beforeDestroy() {
        localStorage.removeItem('openId');
    },
};
</script>
<style lang="less" scoped>
</style>