//引入vant组件
import Vue from 'vue';
import {
    Button,
    Icon,
    RadioGroup,
    Radio,
    Cell,
    CellGroup,
    Stepper,
    Dialog,
    Loading,
    Toast,
    List,
    Picker,
    Checkbox,
    Popup,
    CheckboxGroup,
} from 'vant';
Vue.use(Picker);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Stepper);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(List);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

//vant 弹框方法
Vue.prototype.$dialog = Dialog;
Vue.prototype.$Toast = Toast;