<template>
    <div>
        <div
            class="pament_history"
            v-if="orderRecorder.length>=1"
        >
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了~"
                offset=2
                direction='down'
                @load="onLoad"
            >
                <van-cell
                    v-for="(order,index) in orderRecorder"
                    :key="order.area+index"
                >
                    <template #title>
                        <div>
                            <div class="payment_item box_style">
                                <div class="com_box_style">所在车场 <span class="pay_parking_lot">{{order.area}}</span>
                                </div>
                                <div class="com_box_style">缴费车牌 <span class="pay_plate">{{order.car_no}}</span></div>
                                <div class="com_box_style">支付时间 <span class="pay_time">{{order.pay_time}}</span></div>
                                <div class="com_box_style">缴费金额 <span class="pay_money">￥{{order.pay_amount}}</span>
                                </div>
                                <div
                                    class="com_box_style"
                                    v-if="Number(order.free_amount)>0"
                                >优惠金额 <span class="pay_money">￥{{order.free_amount}}元</span>
                                </div>
                                <div
                                    class="com_box_style"
                                    v-if="Number(order.free_time)>0"
                                >减免时长 <span class="pay_money">{{formatTimeNew(order.free_time)}}</span>
                                </div>
                                <div
                                    class="com_box_style"
                                    v-if="Number(order.bonus)"
                                >消耗积分 <span class="
                                    pay_money">{{order.bonus}}积分</span>
                                </div>
                                <div class="com_box_style">支付方式 <span class="pay_method">{{order.pay_name}}</span></div>
                                <div class="com_box_style">订单发票

                                    <span
                                        id="invoice_btn"
                                        v-if="order.invoice_status==0 || order.invoice_status==-1 "
                                        @click="applyInvoice(order.invoice_web)"
                                    >申请开票</span>
                                    <span
                                        class="pay_invoice"
                                        :style="{color:order.invoice_status==4||5?'#eb7915':'#333333'}"
                                        v-else
                                    >{{filtersTxt(order.invoice_status)}}</span>
                                </div>
                            </div>
                        </div>
                    </template>

                </van-cell>
                <div :style="{textAlign:'center',fontSize:'12px',marginTop:'10px'}">{{moreText}}</div>
            </van-list>

        </div>

        <div
            class="no_recorder"
            v-if="orderRecorder.length<=0"
        >{{tips}}</div>
    </div>

</template>

<script>
import { filtersTxt, formatTimeNew, qsToObj } from '../../config/utils';
export default {
    data: () => ({
        orderRecorder: [],
        allRecorder: [],
        loading: false,
        finished: false,
        page: 1,
        page_size: 3,
        tips: '',
        moreText: '上拉获取更多订单信息',
        openId: '',
    }),
    created() {
        //ios不刷新问题
        window.addEventListener('pageshow', function (e) {
            // 通过persisted属性判断是否存在 BF Cache
            if (e.persisted) {
                window.location.reload();
            }
        });

        this.openId =
            this.$route.query.openId ||
            qsToObj()['openId'] ||
            sessionStorage.getItem('openId') ||
            '';
        //没有获取到openId，使用微信静默授权登录
        !this.openId && this.$http.wechatLogin(window.location.href);
        sessionStorage.setItem('openId', this.openId);

        if (!this.openId) return;
        if (this.openId) {
            this.getRecorders();
        }
    },
    methods: {
        filtersTxt,
        formatTimeNew,
        //申请发票链接
        applyInvoice(src) {
            // location.href = "http://ehrtest.chngsl.com:8060/xhtcpt.html"
            console.log(src,'src');
            // location.href = 'http://localhost:8081/#/payHistoryList';
            // location.href = 'http://localhost:8081/#/payHistoryList';
        },
        //获取记录
        async getRecorders() {
            try {
                let { data } = await this.$http.getPayRecorders({
                    openId: this.openId,
                });

                if (data.length >= 1) {
                    this.allRecorder = data;
                    this.orderRecorder = this.allRecorder.slice(0, 2);
                }
            } catch {
                this.orderRecorder = [];
            }
            this.orderRecorder.length <= 0 && (this.tips = '暂无缴费记录！');
        },

        //加载更多
        onLoad() {
            if (this.page >= this.allRecorder.length + 1) {
                this.finished = true;
                this.moreText = '';
                return;
            }
            if (this.page == 1) {
                this.loading = false;
                ++this.page;
                return;
            }

            setTimeout(() => {
                this.loading = false;
                ++this.page;
                this.orderRecorder = [
                    ...this.allRecorder.slice(0, this.page + 1),
                ];
            });
        },
    },
};
</script>

<style lang="less" scoped>
.van-cell::after {
    border: none !important;
}
.van-cell {
    /*  margin-bottom: 30px; */
    background-color: transparent;
}
.van-list {
    min-height: 100vh;
}

.com_box_style {
    .flex_between;
    align-items: center;
    height: 100px;
    line-height: 100px;
    box-sizing: border-box;
    border-bottom: 1px solid #f6f6f6;
    font-size: 28px;
    color: #999;

    span {
        color: #333;
    }
}
.box_style {
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 40px;
    /*  margin-bottom: 30px; */
    .invoce_status {
        color: #eb7915;
        margin-left: auto;
    }
    & div:last-child {
        border-bottom: none !important;
    }
}
#invoice_btn {
    width: 170px;
    height: 70px;
    line-height: 70px;
    background: #ffffff;
    border: 2px solid #2ec5b6;
    border-radius: 10px;
    color: #2ec5b6;
    text-align: center;
    margin-left: auto;
}

.pament_history {
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
}
.no_recorder {
    height: 100vh;
    background-color: #f6f6f6;
    text-align: center;
    line-height: 100px;
    color: #999;
}
</style>
