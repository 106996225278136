<!--
 * @Description: 支付类型
 * @Author: 胡林佳YoujiDuoQing
 * @Date: 2021-06-24 09:13:27
 * @LastEditors: 胡林佳YoujiDuoQing
 * @LastEditTime: 2021-07-05 14:38:14
-->
<template>
    <div class="pay-type">
        <div v-for="(item,i) in payTypeList" class="item">
            <van-cell>
                <!-- 使用 title 插槽来自定义标题 -->
                <template #title>
                    <div class="pay-item-title">
                        <div class="pay-item-text">
                            <div class="item-text-top">
                                <span>
                                    <span class="pay-item-icon icons">
                                        <img :src="item.url" :alt="item.text">
                                    </span>{{item.text}}
                                </span>
                                <div class="text-top-r">
                                    <span v-if="item.slogan" class="random-offer-text">{{item.slogan}}</span>
                                    <span>
                                        <van-radio checked-color="#2EC5B6" v-model="selectVal" :name="item.val"></van-radio>
                                    </span>
                                </div>
                            </div>
                            <div class="item-text-footer" v-if="item.val==2">
                                <span class="item-pay-val">0.00,</span>
                                <span class="go-recharge">&nbsp;去充值 </span>
                            </div>
                        </div>
                    </div>
                </template>
            </van-cell>
        </div>

    </div>
</template>
<script>
export default {
    name: 'payType',
    props: {
        defaultVal: {
            type: String,
            default: '1'
        },
        payTypeList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    watch: {
        defaultVal: {
            handler: function (newV, val) {
                this.selectVal = newV
            },
            immediate: true
        },
    },
    data () {
        return {
            selectVal: ''
        }
    },
    methods: {
        getVal () {
            return this.selectVal
        },
    },
    created () { },
    mounted () { },
}
</script>
<style lang='less' scoped>
.pay-type {
    /deep/ .van-cell {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    /deep/ .van-cell__title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }
    .item {
        border-bottom: 1px solid #f6f6f6;
    }
    .pay-item-title {
        display: flex;
        align-items: center;
        .pay-item-icon {
            width: 41px;
            height: 41px;
            margin-right: 23px;
            img {
                width: inherit;
                height: initial;
                vertical-align: sub;
            }
        }

        .pay-item-text {
            color: #333333;
            font-size: 28px;
            font-weight: bold;
            flex: auto;
            .item-text-top {
                display: flex;
                justify-content: space-between;

                align-items: center;
                .random-offer-text {
                    font-size: 24px;
                    padding: 0 20px;
                    margin-right: 26px;
                    color: #eb7a16;
                    border: 2px solid #eb7a16;
                    border-radius: 48px;
                    max-width: 300px;
                    overflow: hidden;
                    display: block;
                    text-overflow: ellipsis;
                    white-space: pre;
                }
                .text-top-r {
                    display: flex;
                    align-items: center;
                }
            }
            .item-text-footer {
                width: 100%;
                padding-left: 64px;
                .item-pay-val {
                    color: #999;
                }
                .go-recharge {
                    color: #2ec5b6;
                }
            }
        }
    }
}
</style>