<template>
    <div class="receiveEnvelopes">
        <div class="content">
            <img src="../../assets/img/receive-red-package-bg.png" alt="">
            <div class="show-money"><span class="left"></span>金额 <span style="color: #ff0000;">{{money || '0'}}</span>元</div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'receiveEnvelopes',
  data () {
    return {
        money: 0
    }
  },
  created() { 
      this.money = this.$route.query.num ? this.$route.query.num : '0'
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">

.receiveEnvelopes {
    width: 750px;
    margin: 0 auto;
    min-height: 100vh;
    .content {
        width: 100%;
        height: 100%;
        position: relative;
        img {
            width: 100%;
        }
        .show-money {
            position: absolute;
            top: 340px;
            left: 50%;
            margin-left: 10px;
            text-indent: 30px;
            color: #fff;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            font-size: 34px;
            .left {
                display: block;
                float: left;
                width: 3px;
                height: 30px;
                background-color: #fcde81;
                margin-top: 4px;
            }
        }
    }
}
</style>
