import axios from 'axios';
import {
    Toast,
    Dialog
} from 'vant';
Toast.allowMultiple()
axios.defaults.timeout = 20000;
axios.defaults.headers['Content-Type'] =
    'application/x-www-form-urlencoded;chartset=utf-8;';
let toast = null;
axios.interceptors.request.use(
    (config) => {
        toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 6000,
        });
        return config;
    },
    (error) => {
        toast.clear();
    }
);
axios.interceptors.response.use(
    (response) => {
        // -520(不提示错误信息，不显示入口)、-320(提示错误信息，不显示入口)、-521(不提示错误信息，显示入口)
        if (response.data.code) {
            let {
                code,
                msg,
                data
            } = response.data;
            response.data.code != 200 && response.data.code != -520 && response.data.code != -521 && (toast.clear(), Dialog({
                message: `${msg} !`
            }));
        }

        toast.clear();
        try {
            response.data = parseString(response.data);
        } catch (err) {
            /*  console.log(err); */
        }

        return response.data;
    },

    (error) => {
        toast.clear();
        Toast.fail({
            message: error.message,
            duration: 3000,
        });
    }
);

//解析json格式对象
function parseString(theString) {
    return JSON.parse(theString.replace(/\'/g, '"'));
}