<template>
    <div class="outer_box">
        <div class="plate_box">
            <span
                v-for='(num,index) in $store.state.thePlate'
                :key='num+index'
                class="text_box"
                @click="showKeyBoard($event,index)"
            >{{num}}</span>
            <div
                v-if="$store.state.isNewEnergy"
                class="more_box "
                @click="addNewLetterBox"
            >
                <p>
                    <van-icon
                        name="plus"
                        style="margin-bottom:5px;"
                    />
                </p>
                <p> <span class="add_more">新能源</span></p>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data: () => ({}),
    created() {},
    updated() {
        this.inputCheckedStyle();
    },
    methods: {
        //车牌输入框选中时样式
        inputCheckedStyle() {
            try {
                let tags = [...document.querySelectorAll('.text_box')];
                tags.forEach((_) => {
                    _.classList.remove('input_checked');
                });
                tags[this.$store.state.theLetterIndex].classList.add(
                    'input_checked'
                );
            } catch {}
        },
        showKeyBoard(e, index) {
            if (e != null) {
                e.target.classList.add('input_checked');
            }
            this.$store.commit('setLetterIndex', index);
            this.$store.commit('openKeyboard');
            this.inputCheckedStyle();
        },
        //新能源的letter
        addNewLetterBox(e) {
            this.$store.state.isNewEnergy = false;
            this.$store.commit('setLetterIndex', 7);
            this.$store.commit('plateAddOneIndex');
            this.showKeyBoard(null, 7);
        },
    },
};
</script>
<style lang="less" scoped>
.input_checked {
    border: 2px solid @defCol !important;
}
.outer_box {
    .plate_box {
        .flex_between;
        padding-top: 48px;
        .text_box {
            height: 80px;
            line-height: 80px;
            text-align: center;
            width: 80px;
            box-sizing: border-box;
            border: 2px solid #cccccc;
            border-radius: 10px;
            background-color: #fff;
        }
        .text_box:nth-child(2) {
            margin-right: 14px;
        }

        .more_box {
            display: flex;
            height: 80px;
            width: 80px;
            flex-direction: column;
            justify-content: center;
            color: @fontCol1;
            font-size: 16px;
            text-align: center;
            box-sizing: border-box;
            border: 2px solid #cccccc;
            border-radius: 10px;
            background-color: #fff;
            &:hover {
                border: 2px solid @defCol;
            }
        }
        .add_more {
            font-size: 20px;
            color: @fontCol1;
        }
    }
    .select_cont {
        position: fixed;
    }
}
</style>