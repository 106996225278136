//rem设置
let basesize = 32;
function setRem() {
    let scale = document.documentElement.clientWidth / 750;
    document.documentElement.style.fontSize =
        basesize * Math.min(scale, 2) + 'px';
}
setRem();
window.onresize = function() {
    setRem();
};
