<!--
 * @Description: 无感支付开通页
 * @Author: 胡林佳YoujiDuoQing
 * @Date: 2021-06-24 16:00:32
 * @LastEditors: 胡林佳YoujiDuoQing
 * @LastEditTime: 2021-07-08 09:47:15
-->
<template>
    <div class="senselessPayment">
        <div class="page-top">
            <div class="top-card-no">
                <span class="card-no-text">{{carNo}}</span>
            </div>
            <div class="select-car-color">
                <div class="s-car-color">
                    <div class="car-text0">车辆颜色</div>
                    <div class="cus-cell" @click="show = true">
                        <van-cell is-link>
                            <template #title>
                                <span class="car-text1">{{colorLabel}}</span>
                            </template>
                        </van-cell>
                    </div>
                </div>
            </div>
            <div class="select-car-color  advertising-slogan">
                <div class="s-car-color">
                    <div v-for="item in list" class="advertising-slogan-item">
                        <span class="item-icons"></span>{{item}}
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-btn">
            <van-button type="default" class="btn1" @click="open">确认开通</van-button>
        </div>
        <van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
            <van-picker @cancel="show = false" @confirm="onConfirm" show-toolbar :columns="carColors" />
        </van-popup>

        <van-popup v-model="showGoAppletsBtn" theme="round-button">

            <div class="go-area">
                <div class="is-go">是否确认跳转小程序？</div>
                <div>
                    <wx-open-launch-weapp id="launch-btn" username="gh_518c42c65952" :path="pathParams" @error="handleErrorFn" @launch="handleLaunchFn">

                        <script type="text/wxtag-template">
                            <style>
                                       .btn-warpper {
                                           display:block;
                                           width:295px;
                                        }
                                        .wx-btn {
                                            padding: 30px;
                                            width: 100%;
                                            text-align: center;
                                            margin: auto;
                                        }
                                    </style>
                                    <div class="btn-warpper">
                                        <div class="wx-btn">确认跳转</div>
                                    </div>
                                    </script>
                    </wx-open-launch-weapp>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { isApple } from "../../config/utils"
export default {
    name: 'senselessPayment',
    components: {},
    props: {},
    data () {
        return {
            showGoAppletsBtn: false,
            openId: this.$route.query.openId || sessionStorage.getItem('openId') || '',
            pathParams: '',
            carNo: '',
            carColors: [
                { text: '蓝色', val: 'BLUE' },
                { text: '绿色 ', val: 'GREEN' },
                { text: '黄色 ', val: 'YELLOW' },
                { text: '黑色', val: 'BLACK' },
                { text: '白色', val: 'WHITE' },
                { text: '黄绿色 ', val: 'LEMEGREEN' },
            ],
            show: false,
            list: ['微信无感支付', '享受极速停车离场体验'],
            form: {
                color: '',

            },
            colorLabel: '请选择车辆颜色'
        }
    },
    methods: {
        handleLaunchFn (e) {
            console.log(e)
        },
        handleErrorFn (e) {
            console.log('fail', e.detail)
        },
        reloadPage () {

            if (!isApple()) {
                return
            } else {
                const currUrl = location.href
                const isReload = currUrl.indexOf("&reload=1") > -1 ? true : false
                if (!isReload) {
                    location.replace(currUrl + "&reload=1")
                }
            }
        },
        open () {
            let _this = this
            let _rq = this.$route.query
            let oParams = {
                carNo: _rq.carNo,
                color: this.form.color,
                openId: this.openId,
                area: _rq.area
            }
            this.$http.postAddAutoPayCarNo(oParams).then(res => {
                if (res.code == 200) {
                    let { appId, nonceStr, signature, timestamp, mchId, subMchId } = res.data
                    this.pathParams = `/pages/auth-creditpay/auth-creditpay?mchid=${mchId}&sub_mchid=${subMchId}&openid=${this.$route.query.openId}&plate_number=${this.carNo}&plate_color=${this.form.color}&trade_scene=PARKING`
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                        appId: appId, // 必填，公众号的唯一标识
                        timestamp: timestamp, // 必填，生成签名的时间戳
                        nonceStr: nonceStr, // 必填，生成签名的随机串
                        signature: signature,// 必填，签名
                        jsApiList: ["checkJsApi"], // 必填，需要使用的JS接口列表
                        openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
                    })
                    wx.ready(function () {
                        _this.showGoAppletsBtn = true
                        //config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
                    })
                }
            })
        },
        onConfirm (value, index) {
            this.show = false
            this.colorLabel = value.text
            this.form.color = value.val
        },
    },
    created () {
        this.carNo = this.$route.query.carNo
        this.reloadPage()
    }
}
</script>
<style lang='less' scoped>
.senselessPayment {
    width: 100%;
    height: 1200px;
    overflow-x: hidden;
    /deep/ .van-popup {
        background-color: transparent;
    }
    /deep/ .van-picker__confirm {
        color: #2ec5b6;
    }
    /deep/ .van-picker-column__item--selected {
        color: #2ec5b6;
    }
    .cus-cell /deep/ .van-cell {
        padding: 0px 0px;
        padding-top: 27.5px;
        padding-bottom: 0;
        &:active {
            background-color: #fff;
        }
        .car-text1 {
            font-size: 16px;
            font-weight: 500;
            color: #999999;
        }
    }
    .page-top {
        position: relative;
        height: 299px;
        background: url('../../assets/img/bg.png') 0 0 no-repeat;
        background-size: contain;
        background-position-x: right;
        padding: 0 40px;
        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 115%;
            width: 115%;
            background: #2ec5b6 0 0 no-repeat;
            top: 0;
            left: -8%; //椭圆左边隐藏10%，右边隐藏10%
            right: 0;
            bottom: 0;
            z-index: -1;
            border-radius: 0 0 50% 50%; //左上角，右上角，右下角，左下角
            background-size: contain;
            background-position-x: 87%;
        }
        .card-no-text {
            display: inline-block;
            font-size: 50px;
            font-weight: bold;
            color: #ffffff;
            padding-top: 86px;
            margin-bottom: 60px;
        }
    }
    .select-car-color {
        width: 100%;
        .s-car-color {
            padding: 50px;

            background: #ffffff;
            box-shadow: 0px 2px 16px 0px rgba(147, 149, 153, 0.12);
            border-radius: 16px;
            .car-text0 {
                font-size: 36px;
                font-weight: bold;
                color: #333333;
            }
        }
    }
    .advertising-slogan {
        margin-top: 30px;
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        .advertising-slogan-item {
            margin-bottom: 60px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .item-icons {
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #2ec5b6;
            margin-right: 40px;
        }
    }
    .fixed-btn {
        position: fixed;
        bottom: 30px;
        left: 40px;
        width: calc(100% - 80px);
    }
    .go-area {
        width: 640px;
        padding: 0px 20px;
        border-radius: 16px;
        background-color: #fff;
        .is-go {
            padding: 52px 48px;
            padding-top: 60px;
            color: #646566;
            text-align: center;
            min-width: 320px;
            border-bottom: 1px solid #ebedf0;
        }
    }
}
</style>
