import {
    postMonthlyCardInfo,
    postSaveMonthlyCard
} from '../../api/index';
class MonthlyCard {
    constructor(_info = {}) {
        this.info = _info;
    }

    //数据请求
    //获取月卡信息
    postMonthlyCardInfoFromSev({
        carNo,
        area,
        openId
    }) {
        return postMonthlyCardInfo({
            carNo: carNo,
            area: area,
            openId: openId
        });
    }
    //上报数据
    postSaveCardInfoFromSev({
        telephone,
        carNo,
        orderSn,
        month,
        money,
        beginTime,
        endTime,
        area,
        sys,
        payPlatform
    }) {
        return postSaveMonthlyCard({
            telephone,
            carNo,
            orderSn,
            month,
            money,
            beginTime,
            endTime,
            area,
            sys,
            payPlatform,
        });
    }

    //初始化数据
    initData(data) {
        this.info = data;
    }
    //时间格式化
    formateData(now) {
        now = new Date(now);
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        let date = now.getDate();
        let hour = now.getHours();
        let minu = now.getMinutes();
        let sec = now.getSeconds();
        month < 10 && (month = '0' + month);
        date < 10 && (date = '0' + date);
        hour < 10 && (hour = '0' + hour);
        minu < 10 && (minu = '0' + minu);
        sec < 10 && (sec = '0' + sec);
        return year + '-' + month + '-' + date;
    }
    //计算到期时间
    dutoTime(num = 1) {
        if (!this.info.endTime) return '';
        let date = new Date(this.info.endTime * 1000);
        date.setDate(date.getDate() + 1);
        date.setMonth(date.getMonth() + num);
        if (date.getDate() === 1) {
            let newDate = date.setDate(0);
            return this.formateData(newDate);
        } else {
            return this.formateData(date.setDate(date.getDate() - 1));
        }
    }
    payStartTime() {
        if (!this.info.endTime) return '';
        let start = new Date(this.info.endTime * 1000);
        start.setDate(start.getDate() + 1);
        return this.formateData(start);
    }
    //计算缴费价格
    totalPay(num = 1) {
        return this.info.package[num];
    }
    get startTime() {
        if (!this.info.endTime) return '';
        return this.formateData(this.info.endTime * 1000);
    }
    get payStandard() {
        if (!this.info.package) return '';
        return this.info.package['1'];
    }
    get payOrderSn() {
        return this.info.orderSn;
    }
    get isMore3Days() {
        //  259200 :三天的总秒数
        let newEndTime = this.info.endTime + 259200
        let curTime = Math.round(new Date() / 1000)
        return curTime > newEndTime
    }
    get isMore10Days(){
        let newEndTime = this.info.endTime +  864000 //31536000
        let curTime = Math.round(new Date() / 1000)
        return curTime > newEndTime
    }

}

export default new MonthlyCard();