<!--
 * @Description: 
 * @Author: 胡林佳YoujiDuoQing
 * @Date: 2020-04-24 10:20:51
 * @LastEditors: 胡林佳YoujiDuoQing
 * @LastEditTime: 2020-04-30 19:40:34
 -->
<template>
  <div class="park-result" @touchmove.self.prevent="stopPageSorll">
    <div class="result-succeed-area" v-if="bResTxt == 'success' && httpCode != -516">
      <span>{{ msg }}</span>
    </div>
    <div class="result-err-area" v-if="bResTxt == 'err' && httpCode != -516">
      <span>{{ msg }}</span>
    </div>

    <div class="result-succeed-area moon-Truck" style="width: 100%;" v-if="httpCode == -516">
      <span>月卡车无需扫码支付停车费用,可直接出场。</span>
    </div>
  </div>
</template>
<script>
import { wechatLogin1, qsToObj } from "../../config/utils";
export default {
  name: "resultsPage",
  data() {
    return {
      openId: "",
      bResTxt: "",
      msg: "",
      httpCode: 0,
    };
  },
  methods: {
    stopPageSorll() {
      console.log("阻止页面被滑动");
    },
    getResult() {
      let _obj = this.$route.query;
      var inx = 0;
      var key = "";
      if (_obj.area.indexOf("?") != -1) {
        var inx = 0;
        var key = "";
        inx = _obj.area.indexOf("?") + 5;
        key = _obj.area.substring(inx, _obj.area.length);
        _obj.key = key;
        _obj.area = _obj.area.substring(0, _obj.area.indexOf("?"));
      }
      let oParams = {
        sign: 1,
        area: _obj.area,
        equipCode: _obj.equipCode,
        openId: _obj.openId,
        key: _obj.key || key,
      };
      this.$axios("/Api/noCarEnter", {
        method: "get",
        params: oParams,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.msg = res.data.msg;
            this.bResTxt = "success";
          } else {
            this.bResTxt = "err";
            this.msg = res.data.msg;
          }
        })
        .catch((err) => {
          this.bResTxt = "err";
          this.msg = "失败";
          console.log(err);
        });
    },
  },
  created() {
    this.openId = this.$route.query.openId || qsToObj()["openId"];
    if (!this.openId) {
      wechatLogin1(this.$axios, window.location.href);
    } else {
      this.httpCode = this.$route.query.code || qsToObj()["code"];

      if (this.httpCode && this.httpCode == -516) {
      } else {
        this.getResult();
      }
    }
  },
};
</script>
<style lang="less">
.park-result {
  background-color: #fff;
  height: 100vh;
  > div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    > span {
      position: absolute;
      bottom: -10%;
      font-size: 18px;
      color: #676767;
      letter-spacing: 4px;
      display: inline-block;
      text-align: center;
      width: 100%;
    }
  }
  .result-succeed-area {
    height: 300px;
    background: url("../../assets/img/result-success.png") no-repeat 0 0;
    background-position: 50% 45%;
    background-size: 164px 164px;
  }
  .result-err-area {
    height: 300px;
    background: url("../../assets/img/result-err-.png") no-repeat 0 0;
    background-position: 50% 45%;
    background-size: 164px 164px;
  }
  .moon-Truck {
    bottom: 25px;
    > span {
      font-size: 32px;
    }
  }
}
</style>
