const parkingArea = new Map([
    ['szyb01', ['星河world一期', '小猫']],
    ['szyb02', ['星河world二期', '捷顺']],
    ['szyb03', ['星河world三期', '零壹']],
    ['184', ['广州星河丹堤停车场', '博思高']],
    ['185', ['广州星河山海湾', '富士']],
    ['186', ['广州星河盛世停车场', '博思高']],
    ['269', ['深圳星河时代停车场', '博思高']],
    ['394', ['星河名居停车场', '博思高']],
    ['395', ['星河华居停车场', '博思高']],
    ['429', ['深圳星河丹堤', '博思高']],
    ['489', ['深圳星河国际停车场', '博思高']],
    ['549', ['星河雅居停车场', '博思高']],
    ['614', ['深圳星河世纪', '博思高']],
    ['CZ01', ['星河澜月湾', '科拓']],
    ['CZ06', ['常州星河国际四期停车场八区', '捷顺']],
    ['YHG01', ['银湖谷停车场', '零壹']],
    ['TJBR01', ['天津滨瑞停车场', '捷顺']],
    ['XHZH01', ['深圳星河智荟停车场', '捷顺']],
    ['CZGJ01', ['常州国际一期停车场', '科拓']],
    ['CZDD01', ['常州丹堤停车场', '科拓']],
    ['CZGJ02', ['常州国际二期停车场', '科拓']],
    ['CZGJ03', ['常州国际三期停车场', '捷顺']],
    ['SZLCTX', ['深圳领创天下停车场', '小猫']],
    ['SZFZ01', ['深圳星河发展中心停车场', '博思高']],
    ['ZOTEST', ['零壹测试', '零壹']],
    ['SZCQ01', ['深圳星河传奇停车场', '捷顺']],
    ['GZDY01', ['广州东苑', '富士']],
    ['HQL01', ['深圳红旗岭', '博思高']],
    ['CZDD02', ['常州丹堤二期停车场', '科拓']],
    ['CZGJ08', ['常州星河国际四期七区', '捷顺']],
    ['NJTF01', ['南京星河天赋停车场', '科拓']],
    ['SZRY02', ['深圳星河荣御', '科拓']],
    ['GZGG01', ['广州星恒公馆（壹号公馆）', '科拓']],
    ['GZMH01', ['广州名豪商务中心', '科拓']],
    ['PNMZW', ['普宁星河明珠湾', '零壹']],
    ['HZDD01', ['惠州星河丹堤花园', '科拓']],
    ['HZDD02', ['惠州星河丹堤天睿北区花园', '科拓']],
    ['HZDD03', ['惠州星河丹堤天睿南区花园', '科拓']],
    ['HZCCG', ['惠州CCG', '捷顺']],
    ['CZGJ07', ['常州国际七期', '零壹科技']],
    ['DGSD01', ['东莞星河时代', '科拓标准版']],
    ['CZGJ05', ['常州国际五期', '科拓标准版']],
    ['SZCCT', ['深圳龙华CCT', '科托标准版']],
    ['CZDD03', ['常州丹堤三期', '零壹']],
    ['ZOTEST2', ['测试', '零壹']],
    ['NXCCT', ['宁乡COCOCity停车场', '福特智能']],
]);

export default parkingArea;
