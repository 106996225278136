<template>
  <div>
    <div class="result_outer">
      <!-- 支付成功 -->
      <div class="pay_success" v-if="theShowType === 'ok'">
        <div class="bg_box_outer">
          <div class="bg_box">
            <div class="success_icon">
              <div class="pay_tip_txt">支付成功</div>
            </div>
          </div>
        </div>
        <div class="money_of_pay">
          <span class="money_symbol">￥</span>
          <span class="money_num">{{ theMoney }}</span>
        </div>
        <div class="success_detail_box">
          <div class="com_box_style">
            支付方式 <span class="parking_lot">{{ thePayType }}</span>
          </div>
          <div class="com_box_style" v-if="Number(discountMoney) > 0">
            优惠金额 <span class="parking_lot">￥{{ discountMoney }}元</span>
          </div>
          <div class="com_box_style" v-if="Number(discountTime) > 0">
            减免时长
            <span class="parking_lot">{{ formatTimeNew(discountTime) }}</span>
          </div>
          <div class="com_box_style" v-if="Number(discountScore) > 0">
            消耗积分 <span class="parking_lot">{{ discountScore }}积分</span>
          </div>
          <div class="com_box_style">
            所在车场 <span class="parking_time">{{ theAreaName }}</span>
          </div>
          <div class="com_box_style" v-if="theParkTime">
            停车时长 <span class="current_time">{{ theParkTime }}</span>
          </div>
          <!--  v-if="order.invoice_status==0 || order.invoice_status==-1 "
                            @click="applyInvoice(order.invoice_web)" -->
          <div
            v-if="invoiceBtnShow"
            class="com_box_style"
            @click="goinvoicePage"
          >
            申请发票
            <span
              id="invoice_btn"
              v-if="paymentRecordStatus == 0 || paymentRecordStatus == -1"
              >申请开票</span
            >
            <span
              v-else
              class="pay_invoice"
              :style="{
                color: paymentRecordStatus == 4 || 5 ? '#eb7915' : '#333333',
              }"
              >{{ btnText }}</span
            >
          </div>
        </div>
        <div class="btn2" @click="goToHome">返回首页</div>

        <!-- ad  -->
        <!-- ad placeholder -->
        <div v-if="false" style="margin-top: 30px">
          <a href="http://xinghe.fl.auwinner.com">
            <img
              src="../../assets/img/ic_aa_pay_result.png"
              alt="xinghe"
              style="width: 100%"
            />
          </a>
        </div>
        <!-- ad end -->
      </div>
      <!-- 支付成功 -->

      <!-- 支付失败 -->
      <div class="pay_fail" v-if="theShowType === 'failed'">
        <div class="bg_box_outer">
          <div class="bg_box">
            <div class="success_icon">
              <div class="pay_tip_txt">支付失败</div>
            </div>
          </div>
        </div>
        <div class="money_of_pay">
          <span class="money_symbol">￥</span>
          <span class="money_num">{{ theMoney }}</span>
        </div>
        <div class="btn1" @click="repay">重新支付</div>
      </div>
      <!-- 支付失败 -->
    </div>
  </div>
</template>
<script>
import {
  formatTime,
  formatTimeNew,
  templateShowStyle,
  qsToObj,
  filtersTxt,
} from "./../../config/utils";
import area from "../../config/area";
import { mapState } from "vuex";

export default {
  data: () => ({
    theShowType: "", //展示结果样式
    thePlate: "",
    theMoney: "", //支付金额
    theStartTime: "", //进场时间
    theOrderSn: "", //订单编号
    theParkTime: "", //停车时长
    theArea: "", //停车区域或商家
    thePayType: "", //支付方式
    theAreaName: "",
    carData: {},
    vip_code: "",
    theSys: "",
    discountMoney: "", //优惠金额
    discountTime: "", //优惠时长
    discountScore: "", //消耗积分
    equipCode: "", //设备编号
    key: "", //设备钥
    CRMurl: "",
    invoiceBtnShow: false,
    whiteListLT: [], //临停的发票车场
    whiteListYK: [], //月卡的发票车场
    InvoiceState: [],
    btnText: "",
    paymentRecordStatus: 0,
    gc_invoice_code: "",
    isMonthlyCard: "", //月卡
    // invoicePageUrl: 'http://invoiceweb.pre.z0yhome.com',
    invoicePageUrl: "https://invoiceweb.chngalaxy.com",
  }),
  created() {
    //小程序支付需要的参数
    sessionStorage.setItem("source", this.$route.query.source);
    sessionStorage.setItem("payOpenId", this.$route.query.payOpenId);

    //ios不刷新问题
    window.addEventListener("pageshow", function (e) {
      // 通过persisted属性判断是否存在 BF Cache
      if (e.persisted) {
        window.location.reload();
      }
    });

    this.CRMurl = localStorage.getItem("CRMurl") || "";
    this.discountMoney = this.$route.query.freeAmount || "";
    this.discountTime = this.$route.query.freeTime || "";
    this.discountScore = this.$route.query.score || "";

    this.initDataFromParams();

    let unipay = localStorage.getItem("unionPay");

    if (unipay == "true") {
      //unionPay设置为false，避免影响正常的微信支付流程
      localStorage.setItem("unionPay",false)
      this.GetUnionPayRes().then(res =>{
        console.log(res)
        if (res.code === 200) {
        this.thePayType = "银联支付";
        this.theShowType = "ok"
        this.invoice();
      } else {
        this.theShowType = "failed"
      }
      })
    } else {
      this.judePayWay();
      if (this.theShowType === "ok") {
        this.invoice();
      }
    }
  },

  methods: {
    async GetUnionPayRes(){
      console.log(this.theOrderSn)
      let res = await this.$http.GetUnionPayRes({
        orderSn: this.theOrderSn,
      });
      console.log(res) 
      return res
    },
    formatTimeNew,
    judePayWay() {
      try {
        if (Number(this.theMoney) > 0) {
          this.thePayType = "微信";
        } else {
          this.thePayType = "其他";
        }
      } catch (err) {
        this.thePayType = "微信";
      }
    },
    searchCarInfo() {
      return this.$http.postSearchCarInfo({
        carNo: this.thePlate.replace(/\s/g, ""),
        area: this.theArea,
        openId: this.openId,
      });
    },
    //接收和处理展示信息
    initDataFromParams() {
      this.theShowType = this.$route.query.result || qsToObj()["result"];
      this.carData = JSON.parse(localStorage.getItem("resultData") || "{}");

      this.thePlate = this.carData.plate;
      this.theMoney =
        this.carData.money.toString().indexOf(".") > -1
          ? this.carData.money
          : this.carData.money + ".00";

      this.theStartTime = this.carData.startTime;
      this.theOrderSn = this.carData.orderSn;

      if (this.carData.parkTime) {
        this.theParkTime = formatTimeNew(this.carData.parkTime);
      } else {
        this.theParkTime = "";
      }

      this.theArea = this.carData.area;

      this.theAreaName = this.carData.areaName;
      this.openId = this.carData.openId;
      this.vip_code = this.carData.vip_code;
      this.payPlatform = this.carData.payPlatform;
      this.theSys = this.carData.sys || "";
      this.equipCode = this.carData.equipCode;
      this.key = this.carData.key;
      this.isMonthlyCard = this.carData.isMonthlyCard || "";
    },
    //回到首页
    goToHome() {
      // 判断小程序
      if (this.$route.query.source && this.$route.query.source == "cocogo") {
        // true代表在小程序里
        setTimeout(() => {
          wx.miniProgram.switchTab({
            url: "/pages/home/index",
          });
        });
        return false;
      } else {
        //false代表在公众号里

        this.$store.commit("resetData");
        if (this.CRMurl) {
          localStorage.removeItem("CRMurl");
          location.href = decodeURIComponent(this.CRMurl);
          return;
        }
        this.$router.push({
          path: "/",
          query: {
            area: this.theArea,
            openId: this.openId,
            vip_code: this.vip_code,
          },
        });
      }
    },
    //查询流程支付
    async repayBySearch() {
      let { data } = await this.searchCarInfo();
      if (data) {
        await this.$store.commit("setTempCarInfo", data);
        this.$router.push({
          path: "/useDetail",
          query: {
            area: this.theArea,
            plate: this.thePlate.replace(/\s/g, ""),
            openId: this.openId,
            vip_code: this.vip_code,
            equipCode: this.equipCode || "",
            key: this.key || "",
          },
        });
      } else {
        this.$router.push({
          path: "/",
          query: {
            area: this.theArea,
            openId: this.openId,
            vip_code: this.vip_code,
          },
        });
      }
    },

    //逃费重新支付
    repayByNosearch() {
      this.$router.push({
        path: "/escapeFee",
        query: {
          area: this.theArea,
          plate: this.thePlate.replace(/\s/g, ""),
          payPlatform: this.payPlatform,
          totalFee: this.carData.money,
          orderSn: this.theOrderSn,
          sys: this.theSys,
        },
      });
    },
    //月卡续费重新支付
    monthlyCardRepay() {
      this.$router.push({
        path: "/monthlycard",
        query: {
          area: this.theArea,
          plate: this.thePlate.replace(/\s/g, ""),
          openId: this.openId,
        },
      });
    },

    //支付失败重新支付
    repay() {
      if (this.isMonthlyCard) {
        this.monthlyCardRepay();
        return;
      }
      if (this.payPlatform != 7) {
        this.repayBySearch();
      } else {
        this.repayByNosearch();
      }
    },
    //发票页面
    goinvoicePage() {
      // var newUrl = `${this.invoicePageUrl}/#/pages/invoice/index?price=${this.theMoney}&orderNo=${this.theOrderSn}&time=${this.theStartTime}&resourceCode=PARK&code=${this.gc_invoice_code}&pageType=h5`;
      var newUrl = "http://ehrtest.chngsl.com:8060/xhtcpt.html"
      if (this.paymentRecordStatus == 0 || this.paymentRecordStatus == -1) {
        window.location.href = newUrl;
      }
    },
    async invoice() {
      let { data } = await this.$http.getCatList();
      /*    console.log(data); */
      this.whiteListLT = data.invoice.LT;
      this.whiteListYK = data.invoice.YK;

      // 月卡是否支持发票
      if (this.isMonthlyCard) {
        if (this.whiteListYK.indexOf(this.theArea) != -1) {
          await this.getTelAndsourceCode();
          await this.getInvoiceState();
        }
      } else {
        // 临时缴费是否支持发票
        if (this.whiteListLT.indexOf(this.theArea) != -1) {
          await this.getTelAndsourceCode();
          await this.getInvoiceState();
        }
      }
    },

    // 根据 area 字段获取来源code
    getTelAndsourceCode() {
      var that = this;
      var d2 = new Date().getTime();
      this.$http
        .getInvoiceCode({
          projectCode: "PARK",
          moduleCode: that.theArea,
          random: d2,
        })
        .then((res) => {
          /*   console.log(res, '发票'); */
          if (res.code == 200) {
            that.gc_invoice_code = res.result.gc_invoice_code;
            // //月卡是否支持发票
            // if (isMonthlyCard) {
            //     if (
            //         that.whiteListYK.indexOf(that.theArea) != -1 &&
            //         res.result.gc_invoice_code
            //     ) {
            //         that.invoiceBtnShow = true
            //     } else {
            //         that.invoiceBtnShow = false
            //     }
            //  // 临时缴费是否支持发票
            // } else {
            //     if (
            //         that.whiteListLT.indexOf(that.theArea) != -1 &&
            //         res.result.gc_invoice_code
            //     ) {
            //         that.invoiceBtnShow = true
            //     } else {
            //         that.invoiceBtnShow = false
            //     }
            // }
            if (res.result.gc_invoice_code) {
              that.invoiceBtnShow = true;
            } else {
              that.invoiceBtnShow = false;
            }
          }
        })
        .catch((err) => {
          that.invoiceBtnShow = false;
        });
    },
    //发票状态
    getInvoiceState() {
      let that = this;
      var d2 = new Date().getTime();
      this.$http
        .getInvoiceStatus({
          orderNo: that.theOrderSn,
          random: d2,
        })
        .then((res) => {
          if (res.code == 200) {
            that.InvoiceState = res.result.list;
            that.setInvoiceBtnText();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 设置按钮文案
    setInvoiceBtnText() {
      if (this.InvoiceState.length <= 0) {
        this.paymentRecordStatus = 0;
        this.btnText = filtersTxt(this.paymentRecordStatus);
      } else {
        this.InvoiceState.filter((item) => {
          this.paymentRecordStatus = item.gb_state;
          if (this.theOrderSn == item.gb_order_id) {
            this.paymentRecordStatus = item.gb_state;
            this.btnText = filtersTxt(item.gb_state);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.result_outer {
  #invoice_btn {
    width: 170px;
    height: 70px;
    line-height: 70px;
    background: #ffffff;
    border: 2px solid #2ec5b6;
    border-radius: 10px;
    color: #2ec5b6;
    text-align: center;
    margin-left: auto;
    margin-top: 12px;
  }

  .com_box_style {
    .flex_between;
    height: 80px;
    line-height: 80px;
    box-sizing: border-box;
    border-bottom: 1px solid #f6f6f6;
    font-size: 28px;
    color: #999;

    span {
      color: #333;
    }
  }

  .pay_success {
    padding: 30px;

    .bg_box_outer {
      position: relative;
      min-height: 320px;

      .bg_box {
        position: absolute;
        right: 0px;
        height: 320px;
        width: 414px;
        background: url("../../assets/img/bg.png") no-repeat center center /
          100% 100%;

        .success_icon {
          position: absolute;
          top: 50%;
          left: -30px;
          transform: translateY(-50%);
          height: 160px;
          width: 160px;
          background: url("../../assets/img/pay_success.png") no-repeat center
            center / 100% 100%;

          .pay_tip_txt {
            position: absolute;
            width: 160px;
            text-align: center;
            bottom: -70px;
            font-size: 28px;
            font-family: PingFang SC;
            color: #999999;
          }
        }
      }
    }

    .money_of_pay {
      position: absolute;
      left: 41%;
      margin-top: 30px;
    }

    .success_detail_box {
      padding: 0 30px;
      margin-top: 120px;
    }

    .btn2 {
      margin-top: 140px;
    }
  }

  .pay_fail {
    padding: 30px;

    .bg_box_outer {
      position: relative;
      min-height: 320px;

      .bg_box {
        position: absolute;
        right: 0px;
        height: 320px;
        width: 414px;
        background: url("../../assets/img/bg.png") no-repeat center center /
          100% 100%;

        .success_icon {
          position: absolute;
          top: 50%;
          left: -30px;
          transform: translateY(-50%);
          height: 160px;
          width: 160px;
          background: url("../../assets/img/pay_fail.png") no-repeat center
            center / 100% 100%;

          .pay_tip_txt {
            position: absolute;
            width: 160px;
            text-align: center;
            bottom: -70px;
            font-size: 28px;
            font-family: PingFang SC;
            color: #999999;
          }
        }
      }
    }

    .money_of_pay {
      position: absolute;
      left: 41%;
      margin-top: 30px;
    }

    .success_detail_box {
      padding: 0 30px;
      margin-top: 120px;
    }

    .btn1 {
      margin-top: 140px;
      line-height: 100px;
      text-align: center;
    }
  }
}
</style>
