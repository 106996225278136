// import './config/vconsole';
// import Vconsole from 'vconsole'
import Vue from 'vue'

import * as api from './api/index'
import App from './App.vue'
import './assets/style/normalize.css'
import './assets/style/public.less'
import './config/area'
import './config/axiosLint'
import './config/rem'
import {
    setPageTitle
} from './config/utils'
import './config/vant_import'
import router from './router'
import store from './store'

Vue.prototype.$http = api;
// Vue.prototype.$vConsole = new Vconsole();
Vue.config.productionTip = false;

Vue.config.ignoredElements = ['wx-open-launch-weapp']
/* 设置头部导航标题 */
router.beforeEach((to, from, next) => {
    if (!!to.meta.pageTitle && typeof to.meta.pageTitle !== undefined) {
        setPageTitle(to.meta.pageTitle);
    } else {
        setPageTitle('临时车缴费');
    }
    next();
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');