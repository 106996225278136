<!--
 * @Author: Jackson 张志伟
 * @Date: 2023-05-19 12:41:34
 * @LastEditors: Jackson 张志伟
 * @LastEditTime: 2023-08-02 16:29:54
-->
<template>
  <div style="display: flex;justify-content: center;margin-top: 50px;">
    <!-- 去支付 -->
    正在调起支付，请稍候...
  </div>

</template>
<script>
export default {
  data: () => ({
    data: "",
  }),
  created() {
    var ua = navigator.userAgent.toLowerCase();
    var obj = this.GetRequest();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
      var wxParams = JSON.parse(decodeURIComponent(obj.jsonParams));
      wxParams.backUrl =
        window.location.href.split("#/")[0] + "#/parkPayResult";
      wxParams.source = "park";
      wxParams = encodeURIComponent(JSON.stringify(wxParams));
      setTimeout(() => {
        wx.miniProgram.redirectTo({
          url: "/pages/pay/index?payParam=" + wxParams,
        });
      },500);
      return false;
    } else {
      this.$Toast({
        message: "请在微信小程序内打开",
      });
    }
  },
  methods: {
    GetRequest() {
      var url = location.href; //获取url中"?"符后的字串
      url = url.slice(url.indexOf("?"), url.length);
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
};
</script>
<style>
</style>

